import React, {useEffect, useState} from 'react';
import './Table.css';
import swal from 'sweetalert';
import Select from 'react-select';
import {ScaleLoader} from 'react-spinners';
import _ from 'lodash';
import {ICustomerData, IFlavorData} from '../../interface/Sales';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import {useNavigate} from 'react-router-dom';
import {IEditTableProps} from '@app/interface/editInvoice';
import {useDispatch} from 'react-redux';

const SalesTable = ({
  productFlavor,
  customerData,
  halfName,
  productInfo,
  productPrice,
  invoiceData
}: IEditTableProps) => {
  const [flavors, setFlavors] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused]: any = useState(null);
  // user states
  const [selectedCustomerData, setSelectedCustomerData] = useState<any>([{}]);

  const [customerInvoiceData, setCustomerInvoiceData] = useState<any>([{}]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInvoiceData = () => {
    setCustomerInvoiceData([{}]);

    if (invoiceData) {
      const customer = customerData[0];
      const generateResponse = {
        customerInfo: {
          label:
            customer && `${customer?.custFirstName} ${customer?.custLastName}`,
          value: customer && +customer?.customer_id,
          incentive: customer && customer?.incentive,
          invoiceId: customer && +customer?.invoice_id
        },
        sales: invoiceData.map((data: any) => {
          return {...data, p_qty: data.qty};
        })
      };
      setCustomerInvoiceData([{...generateResponse}]);
      return generateResponse;
    }
  };

  useEffect(() => {
    setFlavors(productFlavor);
  }, [productFlavor]);

  useEffect(() => {
    handleInvoiceData();
  }, [invoiceData, customerData]);

  // *********Helper Functions**********
  const GET_grandTotal = () => {
    const check =
      customerInvoiceData[0] &&
      customerInvoiceData[0]?.sales?.map((data: any): any => {
        return !!data?.qty ? +data?.qty : 0;
      });
    let result: number = 0;
    const fl =
      (customerInvoiceData[0] &&
        customerInvoiceData[0]?.sales?.map((data: any) => {
          result = check?.reduce((value: number, state: number) => {
            return value + state * data?.price;
          }, 0);
        })) ||
      0;
    return result;
  };

  const GET_totalCasesSold = () => {
    const check =
      customerInvoiceData[0] &&
      customerInvoiceData[0]?.sales?.map((data: any): any => {
        return !!data?.qty ? +data?.qty : 0;
      });
    return (
      check?.reduce((value: number, state: number) => {
        return value + state;
      }, 0) || 0
    );
  };

  // ****************************************************
  const getFlavourSales = (salesArray: any[], flavour_index: number): number => {
    const Fl_arr = salesArray?.map((customerData: any) => {
      if (isNaN(customerData?.sales?.length)) {
        return 0;
      }

      const sumOfFlavors = customerData?.sales?.reduce(
        (value: number, state: any, index: number) => {
          return index === +flavour_index
            ? +value + +state?.qty
              ? +state?.qty
              : 0
            : value;
        },
        0
      );
      return +sumOfFlavors;
    });

    const totalFlavoursSold = _.sum(Fl_arr);
    return totalFlavoursSold;
  };

  // *******************On Change Events************************
  const remainingFlavours = (fl_index: number, flavourSold?: number) => {
    const fl_totalQty = flavors?.[fl_index]?.qtyReceived;

    if (!flavourSold) {
      flavourSold = getFlavourSales(customerInvoiceData, fl_index);
    }
    const calculation = +fl_totalQty - +flavourSold!;
    const remainingFlavours = calculation < 0 ? 0 : calculation;
    return remainingFlavours;
  };

  const UpdateRateData = (event: any) => {
    const {name, value} = event?.target;
    // validations
    // 1) check if the value is alphabet do update unless it a number
    if (isNaN(+value)) {
      return;
    }
    const updateCustomerProductPrice = customerInvoiceData?.map(
      (cust_data: any, cust_index: number) => {
        const customer_updated_fl = cust_data?.sales?.map((fl_data: any) => {
          return {
            ...fl_data,
            price: +value
          };
        });
        return {
          ...cust_data,
          product_salePrice: +value,
          sales: customer_updated_fl
        };
      }
    );
    setCustomerInvoiceData(updateCustomerProductPrice);
    return 'success';
  };

  const UpdateFlavorData = (
    event: any,
    flavour_index: number,
    user_index: number | string,
    flavourFld?: any
  ) => {
    const currentValue = +event?.target?.value;
    if (isNaN(currentValue)) return;

    const valueToUpdate = +currentValue;

    const updatedFlavors_Data = customerInvoiceData?.map(
      (cust_data: any, cust_index: number): void => {
        // check ramming flavours
        const update_nestedArr = cust_data['sales']?.map(
          (d: any, index: number) => {
            if (index === +flavour_index) {
              if (+valueToUpdate > +d?.qtyReceived) {
                swal({
                  title: 'Not enough available stock',
                  text: '',
                  icon: 'error',
                  dangerMode: true,
                  closeOnClickOutside: false,
                });
                return {
                  ...d,
                  [flavourFld!]: +d?.qtyReceived
                };
              }
              return {
                ...d,
                [flavourFld!]: +valueToUpdate
              };
            } else {
              return d;
            }
          }
        );

        return cust_data && cust_index === +user_index
          ? {
              ...cust_data,
              sales: update_nestedArr
            }
          : cust_data;
      }
    );

    const fl_totalQty: any =
      updatedFlavors_Data?.[+user_index]?.sales?.[flavour_index]?.qtyReceived;

    const totalFlavoursSold = getFlavourSales(
      updatedFlavors_Data,
      flavour_index
    );
    const calculation = +fl_totalQty - +totalFlavoursSold;

    const check_updatedFlavors_Data = selectedCustomerData?.map(
      (cust_data: any, cust_index: number): void => {
        const update_nestedArr = cust_data['sales']?.map(
          (d: any, index: number) => {
            if (index === +flavour_index) {
              return {
                ...d,
                [flavourFld!]: 0
              };
            } else {
              return d;
            }
          }
        );

        return cust_data && cust_index === +user_index
          ? {
              ...cust_data,
              flavors: update_nestedArr
            }
          : cust_data;
      }
    );
    if (+calculation < 0) {
      swal({
        title: 'Not enough available stock',
        text: '',
        icon: 'warning',
        dangerMode: true,
        closeOnClickOutside: false,
      });
      setCustomerInvoiceData(check_updatedFlavors_Data);
    } else {
      setCustomerInvoiceData(updatedFlavors_Data);
    }
  };
  // *********************Buttons Events**************************
  const UpdateTheInvoice = async (customerInfo: any, customerSales: any) => {
    const generateResponseForApiCall = {
      invoice_id: customerInfo?.invoiceId,
      sales_id: customerSales[0] && customerSales[0].sales_id,
      grand_total: GET_grandTotal(),
      total_cases: GET_totalCasesSold(),
      sales: customerSales.map((item: any) => {
        delete item.qtyReceived;
        delete item.sales_id;
        return {
          ...item,
          total_price: +item?.qty * +item?.price || 0
        };
      })
    };

    try {
      setLoading(true);
      const api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: 'sales/updateSales',
        body: generateResponseForApiCall
      });
      if (api_response) {
        setLoading(false);
        swal({
          title: 'success',
          text: 'invoice updated successfully',
          icon: 'success'
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return loading ? (
    <div>
      <div
        className="d-flex justify-content-center"
        style={{alignItems: 'center'}}
      >
        <ScaleLoader color="#007bff" height={40} />
      </div>
    </div>
  ) : (
    <table className="Table">
      <tr className="Table_thead bg-primary text-capitalize">
        <th className='col-one-freeze' style={{backgroundColor: "#007bff"}}>flavors name</th>
        <th className='col-second-freeze' style={{backgroundColor: "#007bff"}}></th>
        {flavors?.map(({flavourName}: any, index: number) => {
          return <th key={index}>{flavourName}</th>;
        })}
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>

      {/* total */}
      <tr>
        <th className="col-one-freeze text-capitalize">total</th>
        <th className='col-second-freeze'></th>
        {flavors?.map(({qtyReceived}: any, index: number) => {
          return (
            <td key={index} className="Table_text">
              {qtyReceived + getFlavourSales(customerInvoiceData, index) || 0}
            </td>
          );
        })}
        
      </tr>

      {/* allocted */}
      <tr>
        <th className="col-one-freeze text-capitalize">allocted</th>
        <th className='col-second-freeze'></th>
        {flavors?.map((_: any, index: number) => {
          return (
            <td key={index} className="Table_text">
              {getFlavourSales(customerInvoiceData, index)}
            </td>
          );
        })}
      </tr>

      {/* available */}
      <tr>
        <th className="col-one-freeze text-capitalize">available</th>
        <th  className='col-second-freeze'></th>
        {flavors?.map((_: any, index: number) => {
          return (
            <td key={index} className="Table_text">
              {remainingFlavours(index) || 0}
            </td>
          );
        })}
      </tr>

      {/* ********************** Editable Content ********************** */}
      <tr>
        <th className="col-one-freeze text-capitalize Table-Editable_cell">Company name</th>
        <th className="col-second-freeze text-capitalize Table-Editable_cell">rate in $</th>
        {halfName.map((halfName: any, index: number) => {
          return (
            <th
              key={index}
              className={`text-capitalize Table-Editable_cell my-header ${
                isFocused === index ? 'focused' : ''
              }`}
            >
              {halfName}
            </th>
          );
        })}
        <th className="text-capitalize Table-Editable_cell">
          total
          <br />
          (Cases)
        </th>
        <th className="text-capitalize Table-Editable_cell">incentive</th>
        <th className="text-capitalize Table-Editable_cell">
          invoice
          <br />
          total
        </th>
        <th className="text-capitalize Table-Editable_cell">inv#</th>
        <th className="text-capitalize Table-Editable_cell">actions</th>
      </tr>

      {customerInvoiceData?.map((item: any, userIndex: string) => {
        const customerProductPrice: number = item?.sales?.[0]?.price || 0;

        return (
          <tr key={userIndex}>
            <td  className='col-one-freeze'>
              <Select
                className="searchInput"
                value={item?.customerInfo ? item?.customerInfo : 'Try Again'}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                placeholder={
                  flavors?.length <= 0 ? '' : 'Please select a Product fist'
                }
                isDisabled
              />
            </td>
            <td className='col-second-freeze'>
              <input
                className={`text-capitalize Table-Editable_Rate_input `}
                value={
                  item?.product_salePrice
                    ? item?.product_salePrice
                    : customerProductPrice
                    ? customerProductPrice
                    : productPrice
                    ? productPrice
                    : '--'
                }
                type="text"
                name="salesPrice"
                onChange={(e) => UpdateRateData(e)}
              />
            </td>

            {/* array */}
            {item?.sales
              ? item?.sales?.map((fl_item: any, index: number) => {
                  return (
                    <td key={index} className="form-group">
                      <input
                        key={index}
                        id="validationCustom03"
                        className={`text-capitalize form-control Table-Editable_input hideNumber_controls`}
                        onFocus={() => setIsFocused(index)}
                        onBlur={() => setIsFocused(null)}
                        type="number"
                        pattern="\d+"
                        name="flavors"
                        placeholder="0"
                        onChange={(e) =>
                          UpdateFlavorData(e, index, userIndex, 'qty')
                        }
                        value={fl_item?.qty === 0 ? '' : fl_item?.qty}
                      />
                    </td>
                  );
                })
              : flavors?.map(({}: any, index: number) => {
                  return (
                    <td key={index} className="form-group">
                      <input
                        key={index}
                        id="validationCustom03"
                        className="text-capitalize form-control  Table-Editable_input hideNumber_controls"
                        type="number"
                        name="flavors"
                        placeholder="0"
                      />
                    </td>
                  );
                })}

            <td>
              <input
                className="text-capitalize Table-Editable_input"
                value={GET_totalCasesSold()}
                type="text"
                name="Total_cases"
                placeholder="Type Total Cases"
              />
            </td>
            <td>
              <input
                className="text-capitalize Table-Editable_input"
                value={
                  item?.customerInfo?.incentive
                    ? item.customerInfo.incentive
                    : 0
                }
                type="text"
                name="incentive"
                placeholder="Type incentive"
              />
            </td>
            <td>
              <input
                className="text-capitalize Table-Editable_input"
                value={GET_grandTotal()}
                type="text"
                name="invoice_total"
                placeholder="Type invoice totals"
              />
            </td>
            <td>
              <input
                className="text-capitalize Table-Editable_input"
                value={
                  item?.customerInfo?.invoiceId
                    ? item.customerInfo.invoiceId
                    : 0
                }
                type="text"
                name="inv"
                placeholder="Type inv"
              />
            </td>

            {/*  action buttons will go here */}
            <td className="d_flex">
              <button
                type="button"
                style={{display: 'contents', cursor: 'not-allowed'}}
                className="btn mr-2 btn-primary"
              >
                Update The Invoice
              </button>
              <button
                type="button"
                className="btn mr-2 btn-primary"
                onClick={() =>
                  UpdateTheInvoice(item?.customerInfo, item?.sales)
                }
              >
                Update The Invoice
              </button>
              <button
                type="button"
                style={{display: 'contents', cursor: 'not-allowed'}}
                className="btn mr-2 btn-primary"
              >
                Update The Invoice
              </button>
            </td>
          </tr>
        );
      })}

      <div className='stick-btn'>
        <button className={`Table_addNewRowBtn `} onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
    </table>
  );
};

export default SalesTable;
