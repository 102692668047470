import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import swal from 'sweetalert';
import AppButton from '@app/components/button/Button';
import {ScaleLoader} from 'react-spinners';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import moment from 'moment';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

function AgentAmount() {
  const {state}: any = useLocation();
  const id = state.id;
  const agentName = state.agentName;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const [selectedItems, setSelectedItems] = useState<[]>([]);

  useEffect(() => {
    $('#agentTable').DataTable().destroy();
    $('#agentTable').find('tbody').empty();
    getAgentById(id);

    return () => {
      $('#agentTable').DataTable().destroy();
      $('#agentTable').find('tbody').empty();
    };
  }, []);

  const getAgentById = async (id: any) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `agents/getAgentShipments/${id}`
    });
    if (result) {
      setLoading(false);
      const tableData = result.data.map((item: any, index: number) => {
        return {
          sno: index + 1,
          agentName: item.AgentName,
          agent_status_id: item.agent_status_id,
          id: item.id,
          lotNo: item.lotNo,
          order_date: moment(item.OrderDate).format('MM/DD/YYYY'),
          freightCharges: item?.freightCharges,
          dutyCharges: item?.dutyCharges,
          status: item.agent_status_id === 1 ? 'Clear' : 'Pending'
        };
      });

      setTimeout(() => {
        tableData &&
          $('#agentTable').DataTable({
            data: tableData,
            columns: [
              {data: 'sno'},
              {data: 'agentName'},
              {data: 'lotNo'},
              {data: 'order_date'},
              {data: 'freightCharges'},
              {data: 'dutyCharges'},
              {data: 'status'},
              {
                title: 'Actions',
                render: function (data, type, row, meta) {
                  const hasMatch =
                    selectedItems.find((items: any) => items.id === row.id) !==
                    undefined;
                  const checkbox = document.createElement('td');
                  const label = document.createElement('label');
                  const input = document.createElement('input');
                  const div = document.createElement('div');

                  checkbox.appendChild(label);
                  label.appendChild(input);
                  label.appendChild(div);

                  label.className = 'container d-flex justify-content-center';
                  label.id = 'veiw';
                  input.type = 'checkbox';
                  input.defaultChecked = hasMatch;

                  if (row.agent_status_id === 1) {
                    input.disabled = true;
                    input.defaultChecked = true;
                  }

                  div.className = 'checkmark';

                  return checkbox.outerHTML;
                },
                searchable: false,
                orderable: false
              }
            ]
          });
        const table: any = document.querySelector('#agentTable');
        table && table.removeAttribute('style');
        $(document).ready(function () {
          var table = $('#agentTable').DataTable();
          $('#agentTable tbody').on(
            'change',
            'input[type="checkbox"]',
            function () {
              var container = $(this).closest('.container');
              var data = table.row(container.closest('tr')).data();

              if (data) {
                console.log('Change');
                handleItemChange(data, $(this).prop('checked'));
              }
            }
          );
        });
      }, 1000);
    }
  };

  const clearAmount = async () => {
    let response = selectedItems.map((item: any) => {
      return {id: item.id};
    });
    console.log(response);

    await swal({
      title: 'Are you sure ?',
      text: 'You want to update agent credit status ?',
      icon: 'warning',
      buttons: [true, true],
      dangerMode: true,
      closeOnClickOutside: false
    }).then(async (res) => {
      if (res) {
        setLoading(true);

        const data = {
          purchase_orders: response
        };
        const add_api_response = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'post',
          url: `purchaseOrder/updateAgentStatus`,
          body: data
        });

        if (add_api_response) {
          setSelectedItems([]);
          setLoading(false);
          getAgentById(id);
          const model = await swal({
            icon: 'success',
            title: 'Success',
            text: `${add_api_response.data.msg}`,
            closeOnClickOutside: false
          });
        }
      }
    });
  };

  const handleItemChange = (item: any, isChecked: boolean): void => {
    console.log(item, isChecked);
    setSelectedItems((prevSelectedItems: any) => {
      if (isChecked) {
        // Check if item already exists in selectedItems array
        const itemExists = prevSelectedItems.some(
          (prevItem: any) => prevItem.id === item.id
        );
        if (itemExists) {
          return prevSelectedItems; // Item already exists, return the previous array
        } else {
          return [...prevSelectedItems, item]; // Item doesn't exist, add it to the array
        }
      } else {
        return prevSelectedItems.filter(
          (prevItem: any) => prevItem.id !== item.id
        );
      }
    });
  };

  if (loading) {
    return (
      <div>
        <div
          className="d-flex justify-content-center"
          style={{marginTop: '150px', alignItems: 'center'}}
        >
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <>
        <ContentHeader title={`${agentName} Credits`} />
        <section className=" bg-white p-4 rounded m-3">
          <div className="d-flex mb-2 justify-content-sm-end">
            {/* <div
              id="searchInput"
              className="col-4"
              style={{marginLeft: '-7px'}}
            >
              <SearchInput
                placeholder="Lot No.#"
                onChange={(e: any) => handleSearch(e)}
              />
            </div> */}
            <div className="">
              <AppButton
                className="btn-lg"
                children={'Clear & Save'}
                onClick={() => {
                  clearAmount();
                }}
                disabled={selectedItems.length <= 0 ? true : false}
              />
            </div>
          </div>
          <table id="agentTable" className="table table-hover">
            <thead className="bg-primary">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Agent Name</th>
                <th scope="col">Lot No.#</th>
                <th scope="col">Order Date</th>
                <th scope="col">Freight Charges</th>
                <th scope="col">Duty Charges</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
          </table>
          <div className="d-flex mt-2 justify-content-end">
            <AppButton
              onClick={() => {
                navigate(-1);
              }}
              children={'Back'}
            />
          </div>
        </section>
      </>
    </div>
  );
}

export default AgentAmount;
