import React, {useEffect, useLayoutEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import AppButton from '@app/components/button/Button';
import PoshInputs from '@app/components/Forms/InputForms/Inputs';
import {useNavigate} from 'react-router-dom';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import {useDispatch} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import {toast} from 'react-toastify';
import swal from 'sweetalert';
import FileInput from '@app/components/Inputs/FileInput/FileInput';

function CompanyDetails() {
  const [Company, setCompany] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [City, setCity] = useState('');
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [Disable, setDisabled] = useState(true);
  const [picture, setPicture]: any = useState('');
  const [logoUrl, setLogoUrl]: any = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const CompanyDetails_data = {
    City,
    Company,
    Email,
    Phone
  };

  useEffect(() => {
    if (
      City !== '' &&
      Company !== '' &&
      emailRegex.test(Email) &&
      Phone !== ''
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [CompanyDetails_data]);

  useEffect(() => {
    VeiwCompanyDetals();
  }, []);

  const VeiwCompanyDetals = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `company/view`
    });
    if (result) {
      let response = result.data;
      setCompany(response.companyName);
      setPhone(response.Contact);
      setEmail(response.Email);
      setCity(response.City);
      setLogoUrl(response.company_logo);
    }
  };

  const AddCompanyDetails = async () => {
    let result = await swal({
      title: 'Are you sure ?',
      text: 'You want to update company details ?',
      icon: 'warning',
      buttons: [true, true],
      dangerMode: true,
      closeOnClickOutside: false
    });
    if (result) {
      let formData = new FormData();
      formData.append('company_logo', picture);

      const api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: 'products/upload',
        body: formData
      });
      const company_logo_url = api_response;
      const add_api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: 'company/add',
        body: {
          Email: Email,
          Contact: Phone,
          Address: 'Pakistan',
          companyName: Company,
          City: City,
          company_logo: company_logo_url
        }
      });
      if (add_api_response) {
        dispatch(setLoading(false));
        swal({
          title: 'Success',
          text: 'Company details updated successfully.',
          icon: 'success',
          closeOnClickOutside: false
        });
      }
    }
  };

  return (
    <div>
      <ContentHeader title="Company Details" />
      <section className=" bg-white p-4 rounded m-3">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="inputWrapper">
            <PoshInputs
              title="Company Name"
              onChange={(e: any) => {
                setCompany(e.target.value);
              }}
              validate={'*'}
              value={Company}
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              title="Email"
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              value={Email}
              type={'email'}
              readOnly={true}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              title="Phone"
              value={Phone}
              onChange={(e: any) => {
                setPhone(e.target.value);
              }}
              hideControl
              type={'number'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              title="City"
              onChange={(e: any) => {
                setCity(e.target.value);
              }}
              value={City}
              type={'text'}
              readOnly={true}
            />
          </div>
          <div className="row" style={{marginTop: '-20px'}}>
            <div className="col-2">
              <img
                style={{height: '100px', width: '100px'}}
                src={picture ? URL.createObjectURL(picture) : logoUrl}
              />
            </div>
            <div className="col-10">
              <FileInput
                value={picture}
                onChange={(e: any) => {
                  setPicture(e.target.files[0]);
                }}
              />
            </div>
          </div>

          <div
            className="d-flex justify-content-end"
            style={{marginRight: '7px'}}
          >
            <AppButton
              children={'Cancel'}
              className="btn btn-light mr-3"
              onClick={() => {
                navigation('/admin/user-and-role/users/');
              }}
            />
            <AppButton
              children={'Update'}
              disabled={Disable}
              onClick={() => {
                AddCompanyDetails();
              }}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default CompanyDetails;
