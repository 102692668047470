import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import swal from 'sweetalert';

const BarcodeModal = (props: any) => {
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedRows, setEditedRows] = useState<any[]>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [key: number]: string;
  }>({});
  const dispatch = useDispatch();

  const handleEditClick = (id: number) => {
    if (!editableRows.includes(id)) {
      setEditableRows([...editableRows, id]);
    }
  };

  const handleBarcodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const barcodeValue = event.target.value;
    const updatedRow = {
      id: id,
      parent_barcode: barcodeValue
    };

    // Create a copy of the original barcode values from props.data
    const originalBarcodes = [...props.data];

    // Find the index of the row with the matching id in originalBarcodes
    const index = originalBarcodes.findIndex((row) => row.id === id);

    // Update the barcode value in the originalBarcodes array
    if (index !== -1) {
      originalBarcodes[index].parent_barcode = barcodeValue;
    }

    // Check for duplicate barcodes
    const isBarcodeDuplicate = originalBarcodes.some(
      (row) => row.id !== id && row.parent_barcode === barcodeValue
    );

    if (isBarcodeDuplicate) {
      // Set a validation error message
      setValidationErrors({
        ...validationErrors,
        [id]: 'Barcode already in use for another flavor'
      });
    } else {
      // Clear the validation error if no longer a duplicate
      setValidationErrors({...validationErrors, [id]: ''});

      const uniqueBarcodeIds: number[] = [];

      const updatedValidationErrors: any = {...validationErrors};
      originalBarcodes.forEach((row) => {
        const duplicateCount = originalBarcodes.filter(
          (r) => r.parent_barcode === row.parent_barcode
        ).length;

        if (duplicateCount === 1) {
          uniqueBarcodeIds.push(row.id);
          updatedValidationErrors[row.id] = null;
        }
      });
      setValidationErrors(updatedValidationErrors);
      console.log(uniqueBarcodeIds);
    }

    if (!editedRows.find((row) => row.id === id)) {
      setEditedRows([...editedRows, updatedRow]);
    } else {
      setEditedRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? updatedRow : row))
      );
    }
  };

  const hasValidationErrors = Object.values(validationErrors).some(
    (error) => !!error
  );

  // Function to check if there are empty barcodes in editedRows
  const hasEmptyBarcodes = editedRows.some((row) => !row.parent_barcode);

  const updateBarcodes = async () => {
    if (hasEmptyBarcodes) {
      // Show an error message for empty barcodes
      swal({
        title: 'Error',
        text: 'Cannot save with empty barcodes',
        icon: 'error'
      });
      return;
    }

    try {
      const api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: 'shipment/updateBarcodes',
        body: {barcodes: [...editedRows]}
      });
      if (api_response) {
        swal({
          title: 'Success',
          text: 'Barcodes updated successfully',
          icon: 'success'
        });
        setEditableRows([]);
        setEditedRows([]);
        setValidationErrors({});
        props.onHide();
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(editedRows);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* Modal content */}
      <Modal.Header className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-3">
        {/* Table to display data */}
        <div className="table-container">
          <table
            className="table table-hover"
            style={{border: '1px solid #dee2e6'}}
          >
            <thead className="thead bg-light">
              <tr>
                <th scope="col">Product</th>
                <th scope="col">Flavor</th>
                <th scope="col">Status</th>
                <th scope="col">BarCode</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {props.data?.map((items: any) => (
                <tr key={items.id}>
                  <td>{items?.productName ? items?.productName : ''}</td>
                  <td>{items?.flavourName ? items?.flavourName : ''}</td>
                  <td
                    style={{
                      color: items.status === 'UNUSED' ? 'green' : 'red'
                    }}
                  >
                    {items?.status ? items?.status : ''}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control" // Add Bootstrap class
                      value={
                        editedRows.find((row) => row.id === items.id)
                          ?.parent_barcode !== undefined
                          ? editedRows.find((row) => row.id === items.id)
                              ?.parent_barcode
                          : items.parent_barcode
                      }
                      onChange={(event) => handleBarcodeChange(event, items.id)}
                      disabled={!editableRows.includes(items.id)} // Disable if not editable
                    />
                    {validationErrors[items.id] && (
                      <span className="text-danger">
                        {validationErrors[items.id]}
                      </span>
                    )}
                  </td>
                  <td>
                    <img
                      src="/assets/edit.svg"
                      style={{
                        cursor:
                          items.status === 'UNUSED' ? 'pointer' : 'not-allowed'
                      }}
                      onClick={() => {
                        if (items.status === 'UNUSED') {
                          handleEditClick(items.id);
                        }
                      }}
                      alt="edit"
                      width={15}
                      height={15}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* Footer buttons */}
        <Button
          className="btn btn-primary"
          onClick={() => {
            updateBarcodes();
          }}
          disabled={
            editedRows.length === 0 || hasValidationErrors || hasEmptyBarcodes
          }
        >
          Save
        </Button>
        <Button
          className="btn btn-light btn-outline-secondary"
          onClick={() => {
            setEditableRows([]);
            setEditedRows([]);
            setValidationErrors({});
            props.onHide();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BarcodeModal;
