import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import AppButton from '@app/components/button/Button';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import {useDispatch} from 'react-redux';
import SearchInput from '@app/components/Inputs/SearchInput/SearchInput';
import moment from 'moment';

const SalesBarcode = () => {
  const dispatch = useDispatch();
  const [searchInvoive, setSearchInvoive] = useState('');
  const [searchBarcodes, setSearchBarcodes] = useState('');
  const [invoiceData, setInvoiceData]: any = useState([]);

  const ApplyFilters = async () => {
    const api_response = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: 'report/searchByBarcode',
      body: {
        invoice_id: searchInvoive,
        barcode: searchBarcodes
      }
    });
    if (api_response) {
      setInvoiceData(api_response.data);
    }
  };
  return (
    <>
      <ContentHeader title="Sales Barcode" />
      {invoiceData?.invoice && (
        <section className="p-1 rounded m-3">
          <div className="bg-white rounded card-header p-4 shadow-sm">
            <div className="d-flex justify-content-between">
              <h2 className="mb-4 h2 flex-grow-1">
                Invoice #{invoiceData?.invoice && invoiceData?.invoice[0]?.id}
              </h2>
              <div
                style={{
                  border: '2.5px solid #DC3545',
                  marginRight: '12px',
                  borderRadius: '6px'
                }}
              ></div>
              <div>
                <h5 className="mb-0 text-secondary">Invoice Amount</h5>
                <h3 className="mt-2 text-dark">
                  <strong>
                    {invoiceData?.invoice &&
                      (invoiceData?.invoice[0]?.total_amount).toLocaleString(
                        'en-US',
                        {
                          style: 'currency',
                          currency: 'USD'
                        }
                      )}
                  </strong>
                </h3>
              </div>
            </div>
            <div
              className="row align-items-start"
              style={{wordBreak: 'break-all'}}
            >
              <div className="col-2">
                <p className="m-0 text-secondary">Company Name</p>
                <h6 className="mt-1 text-dark text-wrap h6">
                  {invoiceData?.invoice && invoiceData?.invoice[0]?.companyName}
                </h6>
              </div>
              <div className="col-2">
                <p className="m-0 text-secondary">Date</p>
                <h6 className="mt-1 text-dark text-wrap h6">
                  {invoiceData?.invoice &&
                    moment
                      .utc(invoiceData?.invoice[0]?.created_at)
                      .format('MM/DD/YYYY')}
                </h6>
              </div>
              <div className="col-2">
                <p className="m-0 text-secondary">Total Cases</p>
                <h6 className="mt-1 text-dark text-wrap h6">
                  {invoiceData?.invoice && invoiceData?.invoice[0]?.total_cases}
                </h6>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="bg-white p-4 rounded m-3">
        <div className="row mb-2 d-flex  col-lg-justify-content-between">
          <div id="searchInput" className="col-md-4">
            <SearchInput
              placeholder="Search Invoice #"
              value={searchInvoive}
              onChange={(e: any) => {
                setSearchInvoive(e.target.value);
              }}
            />
          </div>
          <div id="searchInput" className="col-md-4">
            <SearchInput
              placeholder="Search Barcode"
              value={searchBarcodes}
              onChange={(e: any) => {
                setSearchBarcodes(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4 d-flex justify-content-start">
            <AppButton
              children={'Apply Filters'}
              onClick={ApplyFilters}
              disabled={searchBarcodes|| searchInvoive ? false : true}
              className="btn-lg btn btn-secondary mb-3 "
            />
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>LOT NO</th>
                <th>DESCRIPTION</th>
                <th>BARCODE</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData &&
                invoiceData?.barcodes?.map((item: any, index: number) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.lotNo}</td>
                      <td>{`${item?.productName} (${item?.flavourName})`}</td>
                      <td>{item?.barcode}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default SalesBarcode;
