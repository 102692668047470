import React, {useEffect, useState} from 'react';
import './input.css';
import ContentHeader from '@app/components/content-header/ContentHeader';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {progressLoader} from '@app/store/reducers/ui';
import axios from 'axios';
import {BASE_URL} from '@app/components/constants/app';
import {ScaleLoader} from 'react-spinners';
import swal from 'sweetalert';
import AppButton from '@app/components/button/Button';
import PoshInputs from './Inputs';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import BarcodeModal from '@app/components/Modal/BarcodeModal';
import CustomLoader from '@app/components/ProgressLoader/CustomLoader';

export const VeiwShipments = () => {
  const veiwState: any = useLocation();
  const [packingList, setPackingList] = useState([]);
  const [modelShow, setModelShow] = useState<any>(false);
  const state = useSelector((state: any) => {
    return state.auth?.token;
  });
  const [loading, setLoading] = useState(true);
  const [fakeLoading, setFakeLoading] = useState(false);
  const [shipmentData, setshipmentData] = useState([]);
  const [recevingData, setrecevingData] = useState([]);
  let navigate: any = useNavigate();
  let dispatch: any = useDispatch();

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${state}`
  };

  useEffect(() => {
    if (veiwState.state.received) {
      getShipmentById(veiwState.state.id);
    } else {
      getShipmentById(veiwState.state);
    }
  }, []);

  const getShipmentById = async (id: number) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `shipment/show/${id}`
    });
    if (result) {
      setLoading(false);
      setshipmentData(result.data.shipment);
      setrecevingData(result.data.orders);
    }
  };

  const getPackingSlip = async (
    puschase_order_id: number,
    product_id: number,
    product_flavour_id: number
  ) => {
    setFakeLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      body: {
        product_id,
        product_flavour_id
      },
      url: `shipment/getPackingSlip/${puschase_order_id}`
    });
    if (result) {
      setPackingList(
        (prev) => (prev = result.data.length > 0 ? result.data : null)
      );
      setFakeLoading(false);
      setModelShow(true);
    }
  };

  const HandleReceive = () => {
    swal({
      title: 'Are you sure ?',
      text: 'You want to update status to received ?',
      icon: 'info',
      buttons: [true, true],
      dangerMode: true,
      closeOnClickOutside: false
    }).then((result) => {
      if (result) {
        let response = axios({
          url: `${BASE_URL}shipment/recieveShipment/${veiwState.state.order_id}`,
          method: 'get',
          headers: config,
          onUploadProgress: (progressEvent: any) => {
            let progressB = (progressEvent.loaded / progressEvent.total) * 100;
            dispatch(progressLoader(progressB));
          },
          onDownloadProgress(progressEvent: {loaded: number; total: number}) {
            let progressB = (progressEvent.loaded / progressEvent.total) * 100;
            dispatch(progressLoader(progressB));
          }
        }).then(async (response) => {
          const model = await swal({
            title: 'Success',
            text: 'Shipment received successfully',
            icon: 'success'
          });
          if (model) navigate('/admin/purchase-and-orders/shipment-receivings');
        });
      }
    });
  };

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {veiwState?.state?.received ? (
        <ContentHeader title="Veiw Receiving " />
      ) : (
        <ContentHeader title="Veiw Shipment " />
      )}
      <section className=" bg-white p-4 rounded mb-4 m-3">
        {shipmentData.map((item: any, index: number) => {
          return (
            <div key={index}>
              <div className="inputWrapper" key={index}>
                <PoshInputs
                  onChange={() => ''}
                  title="Lot No."
                  value={item?.lotNo}
                  disabled={true}
                />
                <PoshInputs
                  onChange={() => ''}
                  title="Airway Bill No."
                  value={item?.airwayNo}
                  disabled={true}
                />
              </div>

              <div className="inputWrapper">
                <PoshInputs
                  onChange={() => ''}
                  title="Weight"
                  value={item?.weight}
                  disabled={true}
                />
                <PoshInputs
                  onChange={() => ''}
                  title="Shipping agent"
                  value={item?.AgentName}
                  disabled={true}
                />
              </div>

              <div className="inputWrapper">
                <PoshInputs
                  onChange={() => ''}
                  title="Dispatched date"
                  value={new Date(item?.dispatchedDate).toLocaleDateString(
                    'en-GB'
                  )}
                  disabled={true}
                />
                <PoshInputs
                  onChange={() => ''}
                  title="Expected delivery date"
                  value={new Date(item?.expectedDate).toLocaleDateString(
                    'en-GB'
                  )}
                  disabled={true}
                />
              </div>

              <div className="inputWrapper">
                <PoshInputs
                  onChange={() => ''}
                  title="Freight chargers"
                  value={item?.freightCharges}
                  disabled={true}
                />
                <PoshInputs
                  onChange={() => ''}
                  title="Duty charges"
                  value={item?.dutyCharges}
                  disabled={true}
                />
              </div>

              <div className=""></div>
            </div>
          );
        })}
      </section>

      <section className=" bg-white p-4 m-3 rounded ">
        <div className="d-flex justify-content-end">
          {veiwState.state.received ? (
            <div className="mb-3">
              <AppButton
                children={'Receive'}
                className="btn btn-dark mr-3"
                onClick={() => HandleReceive()}
              />
              <AppButton
                children={'Back'}
                onClick={() => {
                  navigate('/admin/purchase-and-orders/shipment-receivings');
                }}
              />
            </div>
          ) : (
            <AppButton
              children={'Back'}
              className="mb-3"
              onClick={() => {
                navigate('/admin/purchase-and-orders/shipment-receivings');
              }}
            />
          )}
        </div>
        <div className="table-responsive-xxl">
          <table className="table table-hover">
            <thead className="thead- bg-primary">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Product Name</th>
                <th scope="col">Flavor Name</th>
                <th scope="col">Current Stock</th>
                {/* <th scope="col">Barcode</th> */}
                <th scope="col">Quantity</th>
                <th scope="col">View Bar Codes</th>
              </tr>
            </thead>
            <tbody>
              {recevingData.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.productName}</td>
                    <td>{item.FlavorName}</td>
                    <td>{item.current_stock}</td>
                    {/* <td>{item.Barcode}</td> */}
                    <td>{item.qtyOrdered}</td>
                    <td
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        border: 'none'
                      }}
                    >
                      <img
                        src="/assets/eye.svg"
                        alt=""
                        width={18}
                        onClick={() => {
                          getPackingSlip(
                            item.purchase_order_id,
                            item.product_id,
                            item.flavour_id
                          );
                        }}
                        style={{
                          margin: 'auto',
                          marginTop: '6px',
                          cursor: 'pointer'
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <BarcodeModal
          title={'Product Barcode List'}
          data={packingList}
          show={modelShow}
          onHide={() => setModelShow(false)}
        />
        <CustomLoader show={fakeLoading} />
      </section>
    </div>
  );
};
