import React, {useEffect, useState} from 'react';
import PoshInputs from './Inputs';
import './input.css';
import ContentHeader from '@app/components/content-header/ContentHeader';
import {useLocation, useNavigate} from 'react-router-dom';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {progressLoader} from '@app/store/reducers/ui';
import axios from 'axios';
import {BASE_URL} from '@app/components/constants/app';
import {ScaleLoader} from 'react-spinners';
import swal from 'sweetalert';
import {Table} from 'react-bootstrap';
import IncentiveModal from '@app/components/Modal/Model';
import AppButton from '@app/components/button/Button';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import {toast} from 'react-toastify';

export const UpdateShipments = () => {
  const defaul_FlData = {
    flavourCategory: {id: null, value: null, label: null},
    flavour: {id: null, value: null, label: null},
    BarCode: null,
    currentStock: null,
    quantity: null
  };
  const veiwState: any = useLocation();
  const state = useSelector((state: any) => {
    return state.auth?.token;
  });
  const [selectedOption, setSelectedOption]: any = useState({
    id: null,
    value: null,
    label: null
  });
  const [agent, setagent] = useState();
  const [lotNo, setlotNo] = useState('');
  const [Weight, setWeight] = useState('');
  const [DispatchDate, setDispatchDate] = useState('');
  const [DutyCharges, setDutyCharges] = useState('');
  const [Freight, setFreight] = useState('');
  const [ExpectedDate, setExpectedDate] = useState('');
  const [AirwayNo, setAirwayNo] = useState('');
  const [loading, setLoading] = useState(true);
  const [Products, setProducts]: any = useState('');
  const [Disable, setDisable] = useState(true);
  const [deletedFlavors, setDeletedFlavors] = useState<any>([]);
  const [savedFlavourData, setSavedFlavourData]: any = useState(null);
  const [FlData, setFlData] = useState([defaul_FlData]);
  const dispatch = useDispatch();
  let navigate: any = useNavigate();

  // hardCoded Values
  const flavourFldName = {
    flavourCategory: 'flavourCategory',
    flavour: 'flavour'
  };
  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${state}`
  };

  const receivedId: number = veiwState.state.id;
  const received: number = veiwState.state.received;

  useEffect(() => {
    getShipmentById(veiwState.state | receivedId);
    getAgents();
    getProducts();
  }, []);

  useEffect(() => {
    FlData.some((data: any) => {
      if (data.flavourCategory.id && data.flavour.id && data.quantity > 0) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    });
  }, [FlData]);

  const getShipmentById = async (id: number) => {
    try {
      dispatch(progressLoader(15));
      const result = await axios({
        url: `${BASE_URL}shipment/show/${id}`,
        method: 'get',
        headers: config,
        onUploadProgress: (progressEvent: any) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent: {loaded: number; total: number}) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      });

      if (result) {
        setLoading(false);
        const shipment = result.data.data.shipment;
        shipment.map((item: any) => {
          setlotNo(item.lotNo);
          setAirwayNo(item.airwayNo);
          setFreight(item.freightCharges);
          setDispatchDate(item.dispatchedDate);
          setExpectedDate(item.expectedDate);
          setDutyCharges(item.dutyCharges);
          setWeight(item.weight);
          setSelectedOption({
            label: item.AgentName,
            id: item.shipping_Agent_id,
            value: item.shipping_Agent_id
          });
        });
        const orders = result.data.data.orders;
        const result_arr = orders.map((item: any) => {
          return {
            id: item.id,
            flavourCategory: {
              id: item.product_id,
              value: item.product_id,
              label: item.productName
            },
            flavour: {
              id: item.flavour_id,
              value: item.flavour_id,
              perv_value: item.flavour_id,
              label: item.FlavorName
            },
            BarCode: item.Barcode,
            currentStock: item.current_stock,
            quantity: item.qtyOrdered,
            old_qty: item.qtyOrdered
          };
        });
        setDeletedFlavors([]);
        setFlData(result_arr);
        setSavedFlavourData(orders);
      }
    } catch (error) {
      console.log(error);
      dispatch(progressLoader(100));
      setLoading(true);
    }
  };

  const getAgents = async () => {
    try {
      dispatch(progressLoader(15));
      const res = await axios({
        url: `${BASE_URL}agents/get`,
        headers: config,
        timeout: 1000 * 5,
        onUploadProgress: (progressEvent: any) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent: {loaded: number; total: number}) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      });
      if (res.data.meta.statusCode == 200) {
        setLoading(false);
        const temp = res.data.data.data;
        temp.map((element: any) => {
          return (
            (element['label'] =
              element['firstName'] + ' ' + element['lastName']),
            delete element['firstName'] + ' ' + element['lastName'],
            (element['value'] = element['id'])
          );
        });
        setagent(temp);
      }
    } catch (error) {
      console.log(error);
      dispatch(progressLoader(100));
      setLoading(true);
    }
  };

  const getProducts = async () => {
    try {
      dispatch(progressLoader(15));
      const res = await axios({
        url: `${BASE_URL}products/getAll`,
        headers: config,
        timeout: 1000 * 5,
        onUploadProgress: (progressEvent) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent: {loaded: number; total: number}) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      });
      if (res.data.meta.statusCode == 200) {
        setLoading(false);
        const temp = res.data.data;
        temp.map((element: any) => {
          return (
            (element['label'] = element['productName']),
            delete element['productName'],
            (element['value'] = element['id'])
          );
        });
        setProducts(temp);
      }
    } catch (error) {
      console.log(error);
      dispatch(progressLoader(100));
      setLoading(true);
    }
  };

  const CheckSameDataExits = (
    // fldNameOne: string,
    fldName: string,
    selectedOption: any
    // fldNameTwo?: string
  ) => {
    let FlData_copied: any[] = FlData;
    let isExits: boolean = false;

    if (FlData?.length > 1) {
      FlData_copied.forEach((element: any) => {
        if (+element[fldName]?.id === +selectedOption?.id) {
          isExits = true;
        }
      });

      if (isExits) {
        swal({
          title: 'Error',
          text: `${selectedOption.label.toLowerCase()} already exists.`,
          icon: 'warning',
          buttons: [false, true],
          dangerMode: true,
          closeOnClickOutside: false
        });
      }
    }

    return isExits;
  };

  const handleChange = async (name: string, selectFlavor: any, index: any) => {
    let setValue: any = {
      id: null,
      value: null,
      label: null
    };
    let checkStock: any;
    let flavorsOptions: any;

    if (name === flavourFldName.flavourCategory) {
      // const checkData = CheckSameDataExits(
      //   flavourFldName.flavourCategory,
      //   selectFlavor
      // );
      // if (checkData) return;
      flavorsOptions = await getFlavorOptions(selectFlavor.id, index);
    }

    if (name === flavourFldName.flavour) {
      const checkData = CheckSameDataExits(
        flavourFldName.flavour,
        selectFlavor
      );
      if (checkData) return;
      checkStock = await getStock(+selectFlavor.id);
    }

    setValue = {
      id: +selectFlavor.id,
      value: +selectFlavor.value,
      label: selectFlavor.label
    };

    const updated_fl: any = FlData.map((item: any, RowIndex: number) => {
      const objKeys = Object.keys(item);
      const condition = index === RowIndex && objKeys.includes(name);
      let returnValue = null;

      if (condition) {
        returnValue = checkStock
          ? {
              ...item,
              [name]: setValue,
              BarCode: checkStock.BarCode,
              currentStock: checkStock.currentStock
            }
          : {...item, flavorsOptions: flavorsOptions, [name]: setValue};
      } else {
        returnValue = item;
      }
      if (returnValue) return returnValue;
    });
    setFlData(updated_fl);

    return 'success';
  };

  const getFlavorOptions = async (id: number, RowIndex?: number) => {
    if (!id) {
      return;
    }
    try {
      const result = await axios({
        method: 'get',
        url: `${BASE_URL}productflavors/getFlavor/${+id}`,
        headers: config
      });
      if (result) {
        const temp = result.data.data;
        const flavorOptions = temp.map((element: any) => {
          return {
            id: +element.id,
            value: +element.id,
            label: element.FlavorName
          };
        });
        return flavorOptions;
      }
      return 'success';
    } catch (err) {
      console.error(err);
    }
  };

  const getStock = async (id: number) => {
    let obj = {};
    if (!id) {
      return;
    }
    try {
      const result: any = await axios({
        method: 'get',
        url: `${BASE_URL}productflavors/getFlavorStock/${+id}`,
        headers: config
      });
      if (result) {
        const res = result.data.data;
        res.forEach((item: any) => {
          obj = {
            BarCode: item.Barcode,
            currentStock: item.current_stock
          };
        });
      }
      return obj;
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeFlavor = (e: any, index: any) => {
    const {name, value} = e.target;
    const updated_fl: any = FlData.map((item: any, RowIndex: any) => {
      const objKeys = Object.keys(item);
      return index === RowIndex && objKeys.includes(name)
        ? {...item, [name]: value}
        : item;
    });

    setFlData(updated_fl);
  };

  const Remove = async (Rowindex: number) => {
    const model = await swal({
      title: 'Are you sure ?',
      text: 'You wanna delete this flavor',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      closeOnClickOutside: false
    });
    if (!model) return;

    const newArr = [...FlData];
    newArr.splice(Rowindex, 1);

    const deletedRow: any = FlData.find(
      (data: any, index: number) => index === Rowindex
    );
    if (savedFlavourData) {
      const checkIfFlavourExits = savedFlavourData.find(
        (data: any, index: number) => data.id === deletedRow.id
      );

      if (checkIfFlavourExits) {
        const deletedRow_response = {id: deletedRow.id};
        setDeletedFlavors((prevState: any) => [
          ...prevState,
          deletedRow_response
        ]);
      }
    }
    setFlData(newArr);

    // using early returns for clean code
    return 'success';
  };

  const AddRow = () => {
    setFlData((item: any) => [...item, defaul_FlData]);
  };

  const shipments: any = {
    lotNo: lotNo,
    weight: Weight,
    airwayNo: AirwayNo,
    dispatchedDate: DispatchDate,
    expectedDate: ExpectedDate,
    dutyCharges: DutyCharges,
    freightCharges: Freight,
    shipping_Agent_id: selectedOption.id
  };

  const UpdateShipments = async () => {
    if (lotNo === '') {
      toast.error('Lot No. required');
      return;
    }
    if (AirwayNo === '') {
      toast.error('Airway Bill No. required');
      return;
    }
    if (Weight === '') {
      toast.error('Weight required');
      return;
    }
    if (!selectedOption.id) {
      toast.error('Shipping agent required');
      return;
    }
    if (DispatchDate === '') {
      toast.error('Dispatched Date required');
      return;
    }
    if (ExpectedDate === '') {
      toast.error('Expected Delivery Date required');
      return;
    }
    
    const generateResponse = {
      shipments,
      flavors: FlData.map((item: any) => {
        return {
          product_flavour_id: +item.flavour.id,
          qtyOrdered: +item.quantity,
          prev_qty: item.old_qty || 0
        };
      }),
      del: deletedFlavors
    };

    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      url: `shipment/update/${veiwState.state || veiwState.state?.id}`,
      method: 'post',
      body: generateResponse,
      successMsg: 'Purchase Order Updated Successfully.'
    });

    if (result) {
      navigate('/admin/purchase-and-orders/shipment-receivings');
    }
  };

  const UpdateReceivedShippment = async (id: number) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: `shipment/updateShipment/${id}`,
      body: shipments
    });

    if (result) {
      setLoading(false);
      swal({
        icon: 'success',
        title: 'Success',
        text: 'Purchase Order Updated Successfully.'
      });
    }
  };

  const backToShipment = async () =>{
    const model = await swal({
      title: 'Are you sure ?',
      text: 'You want to back ?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      closeOnClickOutside: false
    });
    if (model) {
      navigate(-1)
    };

  }

  return loading ? (
    <div>
      <div className="d-flex justify-content-center">
        <ScaleLoader color="#007bff" height={40} />
      </div>
    </div>
  ) : (
    <div className="container-fluid">
      <ContentHeader title="Update Shipment" />
      <section className=" bg-white p-4 rounded mb-4 m-3">
        <div className="inputWrapper">
          <PoshInputs
            validate={'*'}
            title="Lot No."
            value={lotNo}
            onChange={(e: any) => {
              setlotNo(e.target.value);
            }}
            type={'text'}
            readOnly={true}
          />
          <PoshInputs
            validate={'*'}
            title="Airway Bill No."
            value={AirwayNo}
            onChange={(e: any) => {
              setAirwayNo(e.target.value);
            }}
            type={'text'}
            readOnly={true}
          />
        </div>

        <div className="inputWrapper">
          <PoshInputs
            validate={'*'}
            title="Weight"
            value={Weight}
            onChange={(e: any) => {
              setWeight(e.target.value);
            }}
            type={'text'}
            readOnly={true}
          />
          <div className="col-md-6" style={{marginTop: '-2px'}}>
            <label
              htmlFor="validationCustom03"
              className="form-label mb-1 d-flex "
            >
              Shipping agent
              <span style={{color: 'red', marginTop: '1px'}}>*</span>
            </label>
            <Select
              value={selectedOption}
              onChange={(e: any) => {
                setSelectedOption(e);
              }}
              options={agent}
              placeholder="Select shipping agent"
            />
          </div>
        </div>

        <div className="inputWrapper">
          <PoshInputs
            validate={'*'}
            title="Dispatched Date"
            value={DispatchDate}
            onChange={(e: any) => {
              setDispatchDate(e.target.value);
            }}
            type={'date'}
            readOnly={true}
          />
          <PoshInputs
            validate={'*'}
            title="Expected Delivery Date"
            value={ExpectedDate}
            onChange={(e: any) => {
              setExpectedDate(e.target.value);
            }}
            type={'date'}
            readOnly={true}
          />
        </div>

        <div className="inputWrapper">
          <PoshInputs
            // validate={"*"}
            title="Duty charges"
            hideControl
            value={DutyCharges}
            onChange={(e: any) => {
              setDutyCharges(e.target.value);
            }}
            type={'number'}
            readOnly={true}
          />
          <PoshInputs
            // validate={"*"}
            title="Freight charges"
            value={Freight}
            hideControl
            onChange={(e: any) => {
              setFreight(e.target.value);
            }}
            type={'number'}
            readOnly={true}
          />
        </div>

        {received ? (
          <div
            className="d-flex justify-content-end my-4"
            style={{marginRight: '4px'}}
          >
            <div>
              <AppButton
                children={'Go Back'}
                onClick={() => {
                  navigate(-1);
                }}
                type="button"
                className="btn btn-light mr-3"
              />
            </div>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => UpdateReceivedShippment(receivedId)}
                type="button"
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
      </section>

      {received ? (
        ''
      ) : (
        <section className=" bg-white p-4 m-3 rounded ">
          <div>
            <div>
              <Table className="table table-bordered bg-light my-2">
                <thead className="bg-light">
                  <tr className="text-start" style={{height: 60}}>
                    <th>Product Category</th>
                    <th>Flavor</th>
                    {/* <th>Barcode</th> */}
                    <th>Current Stock</th>
                    <th>Quantity</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {FlData?.map((items: any, index: any) => {
                    const isExist = FlData.filter(
                      (item: any) => +item.id === +items.id
                    );
                    return (
                      <tr key={index}>
                        <td
                          className=" bg-white"
                          style={{height: '60px', width: '250px'}}
                        >
                          <Select
                            value={items.flavourCategory}
                            name="flavourCategory"
                            onChange={(e: any) =>
                              handleChange(
                                flavourFldName.flavourCategory,
                                e,
                                index
                              )
                            }
                            className={`${
                              isExist.length > 0 ? 'disableFld' : ''
                            } `}
                            options={Products}
                            placeholder="Select Product Categories"
                          />
                        </td>

                        <td
                          className=" bg-white"
                          style={{height: '60px', width: '220px'}}
                        >
                          <Select
                            value={items.flavour}
                            name="flavour"
                            className={`${
                              isExist.length > 0 ? 'disableFld' : ''
                            }`}
                            onChange={(e: any) =>
                              handleChange(flavourFldName.flavour, e, index)
                            }
                            options={items.flavorsOptions}
                            placeholder="Select Flavors"
                          />
                        </td>

                        {/* <td
                          className=" bg-white"
                          style={{height: '60px', width: '120px'}}
                        >
                          <input
                            value={items?.BarCode ? items.BarCode : 0}
                            disabled={true}
                            placeholder="Barcode"
                            className="form-control border-0 bg-transparent main_input"
                          />
                        </td> */}

                        <td
                          className="main_Td bg-white"
                          style={{
                            height: '60px',
                            textAlign: 'center',
                            width: '120px'
                          }}
                        >
                          <input
                            value={
                              items?.currentStock ? items?.currentStock : 0
                            }
                            disabled={true}
                            placeholder="Current Stock"
                            className="form-control border-0 bg-transparent main_input"
                          />
                        </td>

                        <td
                          className="main_Td bg-white"
                          style={{
                            height: '60px',
                            textAlign: 'center',
                            width: '120px'
                          }}
                        >
                          <input
                            name="quantity"
                            type="number"
                            disabled={true}
                            min={0}
                            required
                            value={items.quantity || ''}
                            onChange={(e: any) => onChangeFlavor(e, index)}
                            placeholder="Quantity"
                            className="form-control border-0 bg-transparent hideNumber_controls main_input"
                          />
                        </td>

                        {/* <td
                          className="main_Td bg-white"
                          style={{height: '60px', width: '100px'}}
                        >
                          <button
                            type="button"
                            className="btn btn-dark"
                            onClick={() => Remove(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-dash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                            </svg>
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="my-3">
              <button
                onClick={() => {
                  AddRow();
                }}
                type="button"
                className="btn btn-primary"
              >
                Add New
              </button>
              <div
                className="d-flex justify-content-end"
                style={{marginRight: '4px', marginTop: '-37px'}}
              >
                <div>
                  <AppButton
                    children={'Go Back'}
                    onClick={() => {
                    backToShipment()
                    }}
                    type="button"
                    className="btn btn-light mr-3"
                  />
                </div>
                <div>
                  <button
                    disabled={Disable}
                    className="btn btn-primary"
                    onClick={() => UpdateShipments()}
                    type="button"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
