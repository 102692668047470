import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import AppButton from '@app/components/button/Button';
import Select from 'react-select';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {FaArrowDown, FaArrowUp} from 'react-icons/fa';

const ProductsReport = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [productReports, setProductReports] = useState([]);
  const [flavors, setFlavors] = useState([]);
  const [expandedRows, setExpandedRows]: any = useState([]);
  const [nestedRows, setNestedRows]: any = useState([]);
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');

  const [customerSelectedOption, setCustomerSelectedOption]: any =
    useState(null);
  const [productSelectedOption, setProductSelectedOption]: any = useState(null);
  const [flavorSelectedOption, setFlavorSelectedOption]: any = useState(null);
  let customersArray: any[] = [];
  let productsArray: any[] = [];
  let flavoursArray: any[] = [];
  let totalQtyArray: any[] = [];
  let totalAmountArray: any[] = [];

  useEffect(() => {
    getSalesByReport();
    getAllCustomers();
    getAllProducts();
  }, []);

  useEffect(() => {
    if (productSelectedOption) {
      getflavorsById(productSelectedOption);
    } else {
      setFlavors([]);
      setFlavorSelectedOption(null);
    }
  }, [productSelectedOption]);

  const getSalesByReport = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: 'report/salesByProducts',
      body: {
        from: startDate,
        to: endDate,
        customers: customersArray,
        products: productsArray,
        flavors: flavoursArray
      }
    });
    if (result) {
      setProductReports(result.data);
    }
  };

  const getAllCustomers = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'customers/get'
    });
    if (result) {
      let finalResponse = result?.data?.map((element: any, index: number) => {
        return {
          id: element.id,
          label: `${element.companyName}`,
          value: `${element.companyName}`
        };
      });
      setCustomers(finalResponse);
    }
  };

  const getAllProducts = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'products/get'
    });
    if (result) {
      let response = result?.data;
      let finalResponse = response.map((element: any, index: number) => {
        return {
          id: element.id,
          label: element.productName,
          value: element.productName
        };
      });
      finalResponse.unshift({
        id: '',
        label: `All`,
        value: ''
      });
      setProducts(finalResponse);
    }
  };

  const getflavorsById = async (ids: []) => {
    const flavors = ids.map((item: any) => item.id);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: 'productflavors/getFlavors',
      body: {flavors}
    });
    if (result) {
      let finalResponse = result?.data?.flavors?.map(
        (element: any, index: number) => {
          return {
            id: element.id,
            label: element.flavourName,
            value: element.flavourName
          };
        }
      );
      if (finalResponse.length > 0) {
        finalResponse.unshift({
          id: '',
          label: `All`,
          value: ''
        });
      }
      setFlavors(finalResponse);
    }
  };

  const handleRowClick = (index: number) => {
    const newExpandedRows: any = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows.splice(newExpandedRows.indexOf(index), 1);
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleNestedRowClick = (index: number) => {
    if (index === 0) return;
    const newNestedRows: any = [...nestedRows];
    if (newNestedRows.includes(index)) {
      newNestedRows.splice(newNestedRows.indexOf(index), 1);
    } else {
      newNestedRows.push(index);
    }
    setNestedRows(newNestedRows);
  };

  const ApplyFilters = async () => {
    customersArray = customerSelectedOption?.map((val: any) => {
      return val.id;
    });
    productsArray = productSelectedOption?.map((val: any) => {
      return val.id;
    });

    flavoursArray = flavorSelectedOption?.map((val: any) => {
      return val.id;
    });
    setNestedRows([]);
    setExpandedRows([]);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: `report/salesByProducts`,
      body: {
        from: startDate,
        to: endDate,
        customers: customersArray || [],
        products: productsArray || [],
        flavors: flavoursArray || []
      }
    });
    if (result) {
      setProductReports(result.data);
    }
  };
  const toFixed_norounding = (n: number, p: number = 2) => {
    let result: any = n.toFixed(p);
    result =
      Math.abs(result) <= Math.abs(n)
        ? result
        : (result - Math.sign(n) * Math.pow(0.1, p)).toFixed(p);

    if (result == 0) return (0).toFixed(p);
    else return result;
  };
  const sumArray = (numbers: number[]): number =>
    numbers.reduce((prev, current) => prev + current, 0);

  return (
    <>
      <ContentHeader title="Sales by Product" />
      <section className="bg-white p-4 rounded m-3">
        <div className="row mb-2 d-flex  col-lg-justify-content-between">
          <div className="col-xl-2">
            <div className="input-group input-group-lg mb-3">
              <input
                value={startDate}
                type="date"
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                className="form-control"
                aria-label="From"
                aria-describedby="inputGroup-sizing-sm"
              />
            </div>
          </div>

          <div className="col-xl-2">
            <div className="input-group input-group-lg mb-3">
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="form-control"
                aria-label="From"
                aria-describedby="inputGroup-sizing-sm"
              />
            </div>
          </div>

          <div className="col-xl-2">
            <Select
              isMulti
              placeholder="Select company"
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '5px'
                })
              }}
              options={customers}
              value={customerSelectedOption}
              onChange={(e: any) => setCustomerSelectedOption(e)}
            />
          </div>

          <div className="col-xl-2">
            <Select
              isMulti
              placeholder="Select products"
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '5px'
                })
              }}
              options={products}
              value={productSelectedOption}
              onChange={(e: any) => setProductSelectedOption(e)}
            />
          </div>

          <div className="col-xl-2">
            <Select
              isMulti
              options={flavors}
              placeholder="Select flavors"
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '5px'
                })
              }}
              // isDisabled={productSelectedOption && productSelectedOption[productSelectedOption.length - 1]?.id ? false : true}
              value={flavorSelectedOption}
              onChange={(e: any) => setFlavorSelectedOption(e)}
            />
          </div>

          <div className="col-xl-2 d-flex justify-content-end">
            <AppButton
              children={'Apply Filters'}
              onClick={() => {
                ApplyFilters();
              }}
              className="btn-lg btn btn-secondary mb-3 "
            />
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Product's Name</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Sub Qty</th>
                <th scope="col"></th>
                <th scope="col">Sub Amount</th>
              </tr>
            </thead>
            <tbody>
              {productReports.map((row: any, index: number) => {
                let sales_array = row.sales;

                let qty_array = row.sales.map((item: any) => item.total_cases);

                const qty_sum = sumArray(qty_array);

                // total quantity
                totalQtyArray.push(qty_sum);
                let amount_array = row.sales.map(
                  (item: any) => item.grand_total
                );

                const total_amount = sumArray(amount_array);
                // total amount
                totalAmountArray.push(total_amount);

                return (
                  <React.Fragment key={index}>
                    {qty_sum !== 0 && (
                      <tr
                        onClick={() => handleRowClick(index)}
                        style={
                          expandedRows.includes(index)
                            ? {backgroundColor: '#55595d', color: 'white'}
                            : {}
                        }
                      >
                        <td scope="col">
                          <span>
                            <b>{row.productName}</b>
                          </span>
                        </td>

                        <td scope="col"></td>
                        <td scope="col"></td>
                        <td scope="col"></td>
                        <td scope="col">
                          <b>{toFixed_norounding(qty_sum)}</b>
                        </td>
                        <td scope="col"></td>
                        <td scope="col">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              gap: 5
                            }}
                          >
                            <span
                              style={{
                                flex: 2
                              }}
                            >
                              <b>{total_amount.toFixed(2)}</b>
                            </span>
                            {expandedRows.includes(index) ? (
                              <FaArrowUp />
                            ) : (
                              <FaArrowDown />
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                    {expandedRows.includes(index) &&
                      [
                        {
                          date_title: 'Date',
                          invoice_id: 'Invoice #',
                          customer_title: 'Company',
                          qty_title: 'Qty',
                          amount_title: 'Amount'
                        },
                        ...sales_array
                      ].map((subRow: any, subIndex: number) => {
                        let flavorArray = subRow?.sale_items?.map(
                          (val: any) => {
                            return {
                              price: val?.price || 0,
                              qty: val?.qty || 0,
                              flavorName:
                                val?.productflavor?.flavour?.flavourName
                            };
                          }
                        );
                        const totalQty = flavorArray?.reduce(
                          (prev: number, current: any) => {
                            if (typeof current.qty == 'number') {
                              return prev + +current?.qty;
                            }
                          },
                          0
                        );
                        return (
                          <React.Fragment key={subIndex}>
                            {totalQty > 0 && (
                              <tr
                                style={
                                  nestedRows.includes(subIndex)
                                    ? {
                                        backgroundColor: '#007bff',
                                        color: 'white'
                                      }
                                    : {}
                                }
                                onClick={() => handleNestedRowClick(subIndex)}
                                key={subIndex}
                              >
                                <td scope="col">
                                  {subRow?.date_title ||
                                    moment
                                      .utc(subRow?.invoice?.created_at)
                                      .format('MM/DD/YYYY')}
                                </td>
                                <td scope="col">{subRow?.invoice_id}</td>
                                <td scope="col">
                                  {subRow?.customer_title ||
                                    `${
                                      subRow?.invoice?.customer?.companyName ||
                                      ''
                                    } 
                                    `}
                                </td>
                                <td scope="col"></td>
                                <td scope="col">
                                  {subRow?.qty_title ||
                                    toFixed_norounding(totalQty)}
                                </td>
                                <td scope="col"></td>
                                <td scope="col">
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      gap: 5
                                    }}
                                  >
                                    <span
                                      style={{
                                        flex: 2
                                      }}
                                    >
                                      <b>
                                        {subRow?.amount_title ||
                                          subRow?.grand_total.toFixed(2)}
                                      </b>
                                    </span>
                                    {subIndex !== 0 &&
                                      (nestedRows.includes(subIndex) ? (
                                        <FaArrowUp />
                                      ) : (
                                        <FaArrowDown />
                                      ))}
                                  </div>
                                </td>
                              </tr>
                            )}

                            {nestedRows.includes(subIndex) &&
                              [
                                {
                                  date_title: 'Date',
                                  invoice_id: 'Invoice #',
                                  customer_title: 'Company',
                                  productName: 'Description',
                                  qty: 'Qty',
                                  price: 'Price',
                                  total: 'Total'
                                },
                                ...flavorArray
                              ].map((innerRow: any, innerIndex: number) => (
                                <tr
                                  key={innerIndex}
                                  style={{backgroundColor: '#f1eeee'}}
                                >
                                  <td scope="col">
                                    {innerRow.date_title ||
                                      moment(
                                        subRow?.invoice?.created_at
                                      ).format('MM/DD/YYYY')}
                                  </td>
                                  <td scope="col">
                                    {innerRow?.invoice_id || subRow?.invoice_id}
                                  </td>
                                  <td scope="col">
                                    {innerRow.customer_title ||
                                      `${
                                        subRow?.invoice?.customer
                                          ?.companyName || ''
                                      }`}
                                  </td>
                                  <td scope="col">
                                    {innerRow?.productName ||
                                      `${row.productName} (${innerRow.flavorName})`}
                                  </td>
                                  <td scope="col">
                                    {typeof innerRow.qty == 'number' &&
                                      toFixed_norounding(innerRow.qty)}
                                  </td>
                                  <td scope="col">{innerRow.price}</td>
                                  <td scope="col">
                                    {innerRow.total
                                      ? innerRow.total
                                      : (innerRow.qty * innerRow.price).toFixed(
                                          2
                                        )}
                                  </td>
                                </tr>
                              ))}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <div className="table-responsive">
            <table className="table table-clear table-hover">
              <thead>
                <tr>
                  <th scope="col">TOTAL</th>
                  <th scope="col" style={{width: '26%'}}></th>
                  <th scope="col">
                    {toFixed_norounding(sumArray(totalQtyArray)) || 0}
                  </th>
                  <th scope="col">
                    {sumArray(totalAmountArray).toFixed(2) || 0}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsReport;
