import React, {useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import '../Flavors/AddFlavours.css';
import {useDispatch, useSelector} from 'react-redux';
import {progressLoader} from '@app/store/reducers/ui';
import Select from 'react-select';
import AppButton from '../button/Button';
import Table from 'react-bootstrap/Table';
import swal from 'sweetalert';
import {ScaleLoader} from 'react-spinners';
import IncentiveModal from '../Modal/Model';
import {toast} from 'react-toastify';
import {BASE_URL} from '../constants/app';
import ExcelReader, {GroupedItem} from '../Forms/InputForms/ExcelReader';
import CustomLoader from '../ProgressLoader/CustomLoader';

interface Product {
  id: number;
  productSKU: string;
  productDescription: string;
  status: number;
  productImage: string | null;
  created_at: string;
  volume: string;
  product_image: string | null;
  label: string;
  value: number;
}

type FilterResult<T> = {
  data: T | null;
  exist: boolean;
};

interface FlvOption {
  id: number;
  value: number;
  FlavorName: string;
  Flavor: string;
}

interface ShipDataItem {
  productId: number | '';
  flvId: number | null;
  flavor: string | null;
  quantity: number;
  flavorsWithBC: any[];
  flavorCategory: string;
  fileRow: GroupedItem;
  productExist: boolean;
  flvExist: boolean;
  flvApiData: FlvOption | null;
  flvOpt: FlvOption[] | null;
  productApiData: Product | null;
  currentStocks?: {
    BarCode: string;
    currentStock: number;
  } | null;
}

function ReceivingTable(props: any) {
  const dispatch = useDispatch();

  const state = useSelector((state: any) => {
    return state.auth?.token;
  });

  const [modelShow, setModelShow] = useState<any>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [Disable, setDisable] = useState(true);
  const [shipData, setShipData] = useState<ShipDataItem[]>([]); // Initialize as an empty array
  const [generateBtnDisable, setGenerateBtnDisable] = useState(false);
  const [fileData, setFileData] = useState<GroupedItem[]>([]);

  const [loading, setLoading] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(false); // State for loader visibility

  // hardCoded Values
  const flavourFldName = {
    flavourCategory: 'flavourCategory',
    flavour: 'flavour'
  };

  let navigate: any = useNavigate();

  const shipments = props;

  const allOption = useMemo(() => {
    return products.map((product) => ({
      id: product.id,
      value: product.value.toString(),
      label: product.label
    }));
  }, [products]);

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${state}`
  };

  useEffect(() => {
    const processFileData = async () => {
      let checkedFileData: ShipDataItem[] = [];
      setFakeLoading(true); // Show the loader while processing data

      if (fileData && fileData.length > 0) {
        for (let index = 0; index < fileData.length; index++) {
          const fileRow: GroupedItem = fileData[index];

          const {exist: productExist, data: productApiData} =
            filterObjectsByPropertyValue(
              products,
              'label',
              fileRow.flavorCategory
            );

          if (productExist) {
            const flavorsOption = await getFlavorOptions(
              productApiData?.id as number
            );

            const {
              exist: flvExist,
              data: flvApiData
            }: {exist: boolean; data: any} = filterObjectsByPropertyValue(
              flavorsOption,
              'Flavor',
              fileRow.flavor
            );

            const stock = await getStock(productApiData?.id as number);
            const currentStocks = stock
              ? {
                  BarCode: stock.BarCode || '',
                  currentStock: stock.currentStock || 0
                }
              : null;

            checkedFileData.push({
              productId: productApiData?.id || '',
              flavorCategory: productApiData?.label || fileRow.flavorCategory,
              flvId: flvExist ? flvApiData?.id || '' : '',
              flavor: flvExist
                ? flvApiData?.Flavor || ''
                : fileRow.flavor || '',
              quantity: fileRow.quantity || 0,
              flavorsWithBC: fileRow.productList || [],
              currentStocks,
              fileRow,
              productExist,
              flvExist,
              flvApiData: flvExist ? flvApiData : null,
              flvOpt: flavorsOption,
              productApiData
            });
          } else {
            checkedFileData.push({
              productId: '',
              flavorCategory: fileRow.flavorCategory,
              flvId: null,
              flavor: fileRow.flavor || '',
              quantity: fileRow.quantity || 0,
              flavorsWithBC: fileRow.productList || [],
              currentStocks: null,
              fileRow,
              productExist,
              flvExist: false,
              flvApiData: null,
              flvOpt: null,
              productApiData
            });
          }
        }
      }

      setShipData(checkedFileData);
      setFakeLoading(false); // Hide the loader after processing is done
    };

    processFileData();

    // Cleanup function
    return () => {
      setFakeLoading(false); // Hide the loader if the component is unmounted or if fileData changes
    };
  }, [fileData, products]); // Depend on fileData and products

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const isButtonEnabled = shipData.every((data: ShipDataItem) => {
      return data.productExist && data.flvExist && data.quantity > 0;
    });

    setDisable(!isButtonEnabled);
  }, [shipData]);

  const CheckSameDataExits = (
    data: ShipDataItem[],
    productId: number | string | null,
    flvId: number | null | string
  ): boolean => {
    return data.some(
      (item) =>
        item.productId !== null &&
        item.flvId !== null &&
        productId !== null && // Added null check here
        flvId !== null && // Added null check here
        +item.productId === +productId &&
        +item.flvId === +flvId
    );
  };

  const getStock = async (id: number) => {
    if (!id) {
      return null;
    }
    try {
      const result: any = await axios({
        method: 'get',
        url: `${BASE_URL}productflavors/getFlavorStock/${+id}`,
        headers: config
      });
      if (result) {
        const res = result.data.data;
        return {
          BarCode: res.Barcode || '', // Default to empty string if BarCode is missing
          currentStock: res.current_stock || 0 // Default to 0 if currentStock is missing
        };
      }
      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const handleChange = async (
    fieldName: string,
    selectedOption: any,
    index: number
  ) => {
    try {
      setFakeLoading(true); // Show loading indicator

      const updatedShipData: ShipDataItem[] = [...shipData];
      const currentItem = updatedShipData[index];

      if (fieldName === flavourFldName.flavourCategory) {
        const {id, label} = selectedOption;

        currentItem.productId = id;
        currentItem.flavorCategory = label;

        // Update flavorCategory in flavorsWithBC
        currentItem.flavorsWithBC = currentItem.flavorsWithBC.map(
          (product: any) => ({
            ...product,
            flavorCategory: label
          })
        );

        // Set productExist to true when user selects flavorCategory
        currentItem.productExist = true;

        // Fetch and update flavor options using the selectedOption id
        const flavorsOption = await getFlavorOptions(id);

        if (flavorsOption && flavorsOption.length > 0) {
          // Update the flavor options and properties if the old flavor exists in the new options
          const existingFlavor = currentItem.flavor;
          const selectedFlavor = flavorsOption.find(
            (flavor: any) => flavor.flavor === existingFlavor
          );

          currentItem.flvOpt = flavorsOption;
          currentItem.flvExist = !!selectedFlavor;
          currentItem.flvApiData = selectedFlavor || null;

          if (selectedFlavor) {
            currentItem.flvId = selectedFlavor.id;
            currentItem.flavor = selectedFlavor.value;
          }
        } else {
          // Reset flavor-related properties if no flavor options available
          currentItem.flvOpt = null;
        }
      }

      if (fieldName === flavourFldName.flavour) {
        const {id, value} = selectedOption;

        if (CheckSameDataExits(updatedShipData, currentItem.productId, id)) {
          // Show warning and don't insert data
          swal({
            title: 'Warning',
            text: `Flavors with the selected category and flavor already exist`,
            icon: 'warning',
            buttons: [false, true],
            dangerMode: true,
            closeOnClickOutside: false
          });
          setFakeLoading(false); // Hide loading indicator
          return; // Don't proceed further
        }

        currentItem.flvId = id;
        currentItem.flavor = value;
        currentItem.flvExist = true; // Set flvExist to true when flavor is updated

        // Update flavor property in flavorsWithBC
        currentItem.flavorsWithBC = currentItem.flavorsWithBC.map(
          (product: any) => ({
            ...product,
            flavor: value
          })
        );

        // Fetch and update flavor options using the selectedOption id
        const flavorsOption = await getFlavorOptions(
          currentItem.productId as number
        );

        if (flavorsOption && flavorsOption.length > 0) {
          // Update flavor options and properties
          currentItem.flvOpt = flavorsOption;

          // Fetch and update current stock using the selectedOption id (flvId)
          const stock =
            currentItem.flvId !== null
              ? await getStock(currentItem.flvId as number)
              : null;

          if (stock) {
            currentItem.currentStocks = {
              BarCode: stock.BarCode,
              currentStock: stock.currentStock
            };
          } else {
            currentItem.currentStocks = null;
          }
        } else {
          currentItem.flvOpt = null;
        }
      }

      setShipData(updatedShipData);
      setFakeLoading(false); // Hide loading indicator
    } catch (error) {
      console.error('An error occurred:', error);
      setFakeLoading(false); // Hide loading indicator in case of an error
    }
  };

  const getProducts = async () => {
    try {
      setLoading(true);
      dispatch(progressLoader(15));
      const res = await axios({
        url: `${BASE_URL}products/getAll`,
        headers: config,
        timeout: 1000 * 5,
        onUploadProgress: (progressEvent) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent: {loaded: number; total: number}) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      });
      if (res.data.meta.statusCode == 200) {
        setLoading(false);
        const temp = res.data.data;
        temp.map((element: any) => {
          return (
            (element['label'] = element['productName']),
            delete element['productName'],
            (element['value'] = element['id'])
          );
        });
        setProducts(temp);
      }
    } catch (error) {
      console.log(error);
      dispatch(progressLoader(100));
      setLoading(true);
    }
  };

  const HandleBackValidator = async () => {
    shipData.some(async (data: any) => {
      if (
        (data.flavourCategory && data.flavour) ||
        shipments.airwayNo ||
        shipments.lotNo ||
        shipments.weight ||
        shipments.expectedDate ||
        shipments.dispatchedDate
      ) {
        const model = await swal({
          title: 'Are you sure ?',
          text: 'All inserted data will be lost.',
          buttons: ['Cancel', 'Yes'],
          icon: 'warning'
        });
        if (!model) return;
        navigate(-1);
      } else {
        navigate(-1);
      }
    });
  };

  const addShipments = async () => {
    try {
      dispatch(progressLoader(15));
      setGenerateBtnDisable(true);
      const res = await axios({
        url: `${BASE_URL}purchaseOrder/add`,
        headers: config,
        method: 'post',
        data: {
          shipments,
          flavors: shipData.map((item: ShipDataItem) => {
            let parent_barcode = item.flavorsWithBC.map((elem) => {
              const parent_barcode = elem.parentCaseBarcode;
              // delete elem.parentCaseBarcode;

              return {
                product_id: item.productId,
                product_flavour_id: item.flvId,
                parent_barcode
                // ...elem
              };
            });

            return {
              product_flavour_id: item.flvId,
              qtyOrdered: +item.quantity,
              parent_barcode
            };
          })
        },
        onUploadProgress: (progressEvent) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent: {loaded: number; total: number}) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      });
      if (res.data.meta.statusCode == 200) {
        const model = await swal({
          title: 'Success',
          text: 'Purchase Order Created Successfully.',
          icon: 'success'
        });
        if (model) {
          navigate('/admin/purchase-and-orders/shipment-receivings');
        }
      }
    } catch (error: any) {
      // setFileData([])
      setModelShow(false);
      setGenerateBtnDisable(false);
      const model: any = swal({
        title: 'Error',
        text: `${error.response.data.data.message}`,
        icon: 'error'
      });
      if (!model) {
        navigate(0);
      }
    }
  };

  const HandleValidator = () => {
    if (!CheckProps.lotNo) {
      toast.error('Lot No. required');
      return;
    }
    if (CheckProps.airwayNo === '') {
      toast.error('Airway Bill No. required');
      return;
    }
    if (CheckProps.weight === '') {
      toast.error('Weight required');
      return;
    }
    if (!CheckProps.shipping_Agent_id) {
      toast.error('Shipping agent required');
      return;
    }
    if (CheckProps.dispatchedDate === '') {
      toast.error('Dispatched date required');
      return;
    }
    if (CheckProps.expectedDate === '') {
      toast.error('Expected delivery date required');
      return;
    }
    setModelShow(true);
  };

  const CheckProps = {
    shipping_Agent_id: props.shipping_Agent_id || null,
    airwayNo: props?.airwayNo || '',
    weight: props?.weight || '',
    lotNo: props?.lotNo || '',
    expectedDate: props?.expectedDate || '',
    dispatchedDate: props?.dispatchedDate || ''
  };

  // TODO:
  function filterObjectsByPropertyValue<T>(
    objectsArray: T[],
    propertyName: keyof T,
    propertyValue: T[keyof T]
  ): FilterResult<T> {
    const filteredData = objectsArray.find((obj) => {
      const property = obj[propertyName];
      if (typeof property === 'string' && typeof propertyValue === 'string') {
        return property.toLowerCase() === propertyValue.toLowerCase();
      } else {
        return property === propertyValue;
      }
    });

    const exist = filteredData !== undefined;

    return {
      data: filteredData !== undefined ? filteredData : null,
      exist: exist
    };
  }

  const getFlavorOptions = async (id: number, RowIndex?: number) => {
    if (!id) {
      return;
    }
    try {
      const result = await axios({
        method: 'get',
        url: `${BASE_URL}productflavors/getFlavor/${+id}`,
        headers: config
      });
      if (result) {
        const data = result.data.data;
        return data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return loading ? (
    <div>
      <div className="d-flex justify-content-center">
        <ScaleLoader color="#007bff" height={40} />
      </div>
    </div>
  ) : (
    <div>
      <div className="table-responsive-xxl" style={{overflowX: 'clip'}}>
        <ExcelReader fileState={[fileData, setFileData]} />
        {shipData && shipData.length > 0 ? (
          <Table className="table table-bordered bg-light my-2">
            <thead className="bg-light">
              <tr className="text-start" style={{height: 60}}>
                <th>Product Category</th>
                <th>Flavor</th>
                <th>Current Stock</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {shipData.length > 0
                ? shipData.map((item: ShipDataItem, index: number) => {
                    return (
                      <tr key={index}>
                        <td
                          className="bg-white"
                          style={{
                            height: '60px',
                            width: '250px'
                          }}
                        >
                          <div className="column-wrapper">
                            {!item.productExist ? (
                              <div style={{color: 'red'}}> &#x2715;</div>
                            ) : null}
                            <Select
                              className="flex-1"
                              name="flavourCategory"
                              options={allOption}
                              isDisabled={item.productExist}
                              value={{
                                id: item.productId || '',
                                value: item.flavorCategory,
                                label: item.flavorCategory
                              }}
                              onChange={(selectedOption) =>
                                handleChange(
                                  flavourFldName.flavourCategory,
                                  selectedOption,
                                  index
                                )
                              }
                              placeholder="Select Product Category"
                            />
                          </div>
                        </td>

                        <td
                          className="bg-white"
                          style={{
                            height: '60px',
                            width: '220px'
                          }}
                        >
                          <div className="column-wrapper">
                            {!item.flvExist ? (
                              <div style={{color: 'red'}}> &#x2715;</div>
                            ) : null}
                            <Select
                              className="flex-1"
                              name="flavour"
                              value={{
                                id: item.flvId || '',
                                value: item.flavor || '',
                                label: item.flavor || ''
                              }}
                              isDisabled={item.flvExist}
                              onChange={(selectedOption) =>
                                handleChange(
                                  flavourFldName.flavour,
                                  selectedOption,
                                  index
                                )
                              }
                              options={
                                item.flvOpt
                                  ? item.flvOpt.map((option) => ({
                                      id: option.id,
                                      value: option.Flavor,
                                      label: option.FlavorName
                                    }))
                                  : []
                              }
                              placeholder="Select Flavor"
                            />
                          </div>
                        </td>

                        <td
                          className="main_Td bg-white"
                          style={{
                            height: '60px',
                            textAlign: 'center',
                            width: '120px'
                          }}
                        >
                          <input
                            value={item.currentStocks?.currentStock || '0'}
                            disabled
                            placeholder="Current Stock"
                            className="form-control border-0 bg-transparent main_input"
                          />
                        </td>

                        <td
                          className="main_Td bg-white"
                          style={{
                            height: '60px',
                            textAlign: 'center',
                            width: '120px'
                          }}
                        >
                          <input
                            name="quantity"
                            min={0}
                            disabled={true}
                            required
                            type="number"
                            value={item.quantity}
                            placeholder="0"
                            className="form-control border-0 bg-transparent main_input hideNumber_controls"
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        ) : null}
      </div>

      {shipData && shipData.length > 0 ? (
        <div className="my-3">
          <div
            className="d-flex justify-content-end"
            style={{marginRight: '4px'}}
          >
            <div>
              <AppButton
                children={'Go Back'}
                onClick={() => {
                  HandleBackValidator();
                }}
                type="button"
                className="btn btn-light mr-3"
              />
            </div>
            <div>
              <button
                className="btn btn-primary"
                disabled={Disable}
                onClick={() => HandleValidator()}
                type="button"
              >
                Verify & Generate
              </button>
              <IncentiveModal
                title={'Confirm Order'}
                data={shipData}
                show={modelShow}
                generateBtnDisable={generateBtnDisable}
                onHide={() => setModelShow(false)}
                addShipments={() => addShipments()}
              />
            </div>
          </div>
        </div>
      ) : null}
      <CustomLoader show={fakeLoading} />
    </div>
  );
}

export default ReceivingTable;
