import React, {useEffect, useState} from 'react';
import ContentHeader from '../content-header/ContentHeader';
import PoshInputs from '../Forms/InputForms/Inputs';
import './Addcustomers.css';
import {useLocation, useNavigate} from 'react-router-dom';
import AppButton from '../button/Button';
import {useDispatch, useSelector} from 'react-redux';
import swal from 'sweetalert';
import axios from 'axios';
import {BASE_URL} from '../constants/app';
import {progressLoader} from '@app/store/reducers/ui';
import {ActiveDropdown} from '../dropdown/ActiveDropdown';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import { citiesByState } from '@app/enum/Cities';
import Select from 'react-select';

function UpdateCustomers(props: any) {
  console.log(props)
  const location: any = useLocation();
  const userData = location.state;
  const [FirstNameInput, setFirstNameInput]: any = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [disabled, setDisabled] = useState(true);
  const [CompanyName, setCompanyName] = useState();
  const [Zipcode, setZipcode] = useState();
  const [CustomerRefId, setCustomerRefId] = useState();
  const [Status, setStatus] = useState();
  const [BillAddress, setBilladdress] = useState();
  const [Tax, setTax] = useState();
  const [selectedState, setSelectedState]: any = useState(null);
  const [selectedCity, setSelectedCity]: any = useState(null)

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const token = useSelector((state: any) => {
    return state.auth?.token;
  });

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  };

  const States = {
    FirstNameInput,
    lastName,
    address,
    phone,
    email,
    selectedCity,
    selectedState,
    CompanyName,
    Zipcode,
    BillAddress,
  };

  useEffect(() => {
    if (
      FirstNameInput &&
      lastName &&
      address &&
      phone &&
      emailRegex.test(email!) &&
      selectedCity &&
      selectedState &&
      CompanyName &&
      Zipcode &&
      BillAddress
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [States]);

  useEffect(()=>{
    VeiwCustomers(userData)
  },[])

  const VeiwCustomers = async (id: number) => {
    const result = await apiResponseGenerator(
      {
        dispatchFunction: dispatch,
        method: "get",
        url: `customers/show/${id}`
      }
    );
    if (result) {
      let response = result.data
      setCompanyName(response.companyName)
      setFirstNameInput(response.custFirstName)
      setLastName(response.custLastName)
      setEmail(response.custEmail)
      setPhone(response.custContact)
      setCustomerRefId(response.customer_ref_id)
      setBilladdress(response.billAddress)
      setAddress(response.custaddress)
      setZipcode(response.zipcode)
      setTax(response.tax)
      setStatus(response.status)
      setSelectedCity({
        value: response.city,
        label: response.city,
      })
      setSelectedState({
        value: response.state,
        label: response.state,
      })
    }
  }

  const modal = (e: any) => {
    e.preventDefault();
    swal({
      title: 'Are you sure ?',
      text: 'You want to update customer.',
      icon: 'warning',
      buttons: [true, true],
      dangerMode: true,
      closeOnClickOutside: false,
    }).then((update) => {
      if (update) {
        const AddFlavourApi = async () => {
          dispatch(progressLoader(25));
          try {
            axios({
              method: 'post',
              url: `${BASE_URL}customers/update/${userData}`,
              headers: config,
              data: {
                custFirstName: FirstNameInput,
                custLastName: lastName,
                custaddress: address,
                custContact: phone,
                custEmail: email,
                customer_ref_id: CustomerRefId,
                companyName: CompanyName,
                zipcode: Zipcode,
                status: Status,
                billAddress: BillAddress,
                state: selectedState.value,
                city: selectedCity.value,
                tax: +Tax!
              },
              onUploadProgress: (progressEvent) => {
                let progressB =
                  (progressEvent.loaded / progressEvent.total) * 100;
                dispatch(progressLoader(progressB));
                // setProgress(progressB)
              }
            })
              .then((res) => {
                // console.log(res.data.meta.);
                if (res.data.meta.statusCode == 200) {
                  swal({
                    icon: 'success',
                    title: `Success`,
                    text: `Customer updated successfully.`
                  });
                  setTimeout(() => {
                    navigate('/admin/customers');
                  }, 500);
                } else {
                  swal('Something went wrong !', {
                    icon: 'failed'
                  });
                }
              })
              .catch((e) => {
                swal('Something went wrong !');
              });
          } catch (error) {
            swal('Customer has failed to add !', {
              icon: 'failed'
            });
          }
        };
        AddFlavourApi();
      }
    });
  };
  const stateOptions = Object.keys(citiesByState)?.map((state) => ({
    value: state,
    label: state
  }));

  const cityOptions = selectedState
    ? citiesByState[selectedState.value]?.map((city: any) => ({
        value: city,
        label: city
      }))
    : [];

  return (
    <div>
      <ContentHeader title="Update Customer" />
      <section className=" bg-white  p-4 rounded m-3">
        <div>
          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              value={CompanyName}
              onChange={(e: any) => {
                setCompanyName(e.target.value);
              }}
              title="Company Name"
              type={'email'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              value={FirstNameInput}
              onChange={(e: any) => {
                setFirstNameInput(e.target.value);
              }}
              title="First Name"
              type={'text'}
              readOnly={true}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              value={lastName}
              onChange={(e: any) => {
                setLastName(e.target.value);
              }}
              title="Last Name"
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              title="Email"
              type={'email'}
              readOnly={true}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              value={phone}
              onChange={(e: any) => {
                setPhone(e.target.value);
              }}
              title="Phone"
              type={'number'}
              readOnly={true}
            />
             <ActiveDropdown
              validate={'*'}
              value={Status}
              title="Status"
              onChange={(e: any) => {
                setStatus(e.target.value);
              }}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              value={address}
              onChange={(e: any) => {
                setAddress(e.target.value);
              }}
              title="Ship Address"
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              value={BillAddress}
              onChange={(e: any) => {
                setBilladdress(e.target.value);
              }}
              title="Bill Address"
              type={'text'}
              readOnly={true}
            />
          </div>

          <div className="inputWrapper">
            <div className="col-md-6 form-group">
              <label
                htmlFor="validationCustom03"
                className="form-label mb-2 ml-1 d-flex"
              >
                <span>State</span>
                <span style={{color: 'red'}}>*</span>
              </label>
              <Select
                isClearable
                value={selectedState}
                options={stateOptions}
                onChange={setSelectedState}
                placeholder="Select a state"
              />
            </div>
            <div className="col-md-6 form-group">
              <label
                htmlFor="validationCustom03"
                className="form-label mb-2 ml-1 d-flex"
              >
                <span>City</span>
                <span style={{color: 'red'}}>*</span>
              </label>
              <Select
                isClearable
                value={selectedCity}
                options={cityOptions}
                onChange={setSelectedCity}
                placeholder="Select a city"
                isDisabled={!selectedState}
              />
            </div>
          </div>

          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              value={Zipcode}
              onChange={(e: any) => {
                setZipcode(e.target.value);
              }}
              hideControl
              title="Zipcode"
              type={'number'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              value={Tax}
              title="Tax"
              onChange={(e: any) => {
                setTax(e.target.value);
              }}
              hideControl
              type={'number'}
              readOnly={true}
            />
            
          </div>
          {/* <div className="inputWrapper">
            <ActiveDropdown
              validate={'*'}
              value={Status}
              title="Status"
              onChange={(e: any) => {
                setStatus(e.target.value);
              }}
            />
          </div> */}

          <div
            className="d-flex justify-content-end"
            style={{marginRight: '8px'}}
          >
            <AppButton
              children={'Cancel'}
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-light mr-3"
            />
            <AppButton
              disabled={disabled}
              onClick={(e: any) => {
                modal(e);
              }}
              children={'Save'}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default UpdateCustomers;
