import {Link, useNavigate} from 'react-router-dom';
import React, {useState, useEffect, useCallback} from 'react';
import './Tableinput.css';
import AppButton from '../button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import {ScaleLoader} from 'react-spinners';
import {downloadExcel} from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import swal from 'sweetalert';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import {citiesByState} from '@app/enum/Cities';
import Select from 'react-select';

const CustomersTable = ({page}: any) => {
  let navigate = useNavigate();
  const [allCustomers, setallCustomers] = useState([]);
  const loading = useSelector((state: any) => state.ui.loading);
  let local: any = localStorage.getItem('userInfo');
  const permissionsArray = JSON.parse(local).permissions;
  const dispatch = useDispatch();
  const [selectedState, setSelectedState]: any = useState(null);
  const [selectedCity, setSelectedCity]: any = useState(null);

  const getAllCustomes = async () => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'customers/getCustomer'
    });

    if (result) {
      setLoading(false);
      let response = result.data;
      setallCustomers(response);
      const tableData = response.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item.id,
          companyName: item.companyName,
          name: item.custFirstName + ' ' + item.custLastName,
          billAddress: item.billAddress,
          custEmail: item.custEmail,
          custContact: item.custContact,
          custaddress: item.custaddress,
          state: item.state,
          city: item.city,
          zipcode: item.zipcode,
          status: item.status == '1' ? 'Active' : 'In-Active',
          tax: item?.tax || '-'
        };
      });

      setTimeout(() => {
        tableData &&
          $('#customerTable').DataTable({
            data: tableData,
            columns: [
              {data: 'index'},
              {data: 'companyName'},
              {data: 'name'},
              {data: 'custEmail'},
              {data: 'custContact'},
              {data: 'tax'},
              {data: 'custaddress'},
              {data: 'billAddress'},
              {data: 'state'},
              {data: 'city'},
              {data: 'zipcode'},
              {data: 'status'},
              {
                title: 'Action',
                render: function (data, type, row, meta) {
                  const deleteIcon = `<img src="/assets/delete.svg"
                  data-toggle="tooltip"
                  data-html="true"
                  data-bs-placement="top"
                  id="delete"
                  title="Delete" height="600"
                  >`;

                  let editIcon = `<img src="/assets/edit.svg"
                  data-toggle="tooltip"
                  id="edit"
                  data-html="true"
                  data-bs-placement="top"
                  title="Edit" height="600"
                  >`;

                  let viewIcon = `<img src="/assets/eye.svg"
                  data-toggle="tooltip"
                  id="view"
                  data-html="true"
                  data-bs-placement="top"
                  title="View" height="600"
                  >`;

                  let actionIcons =
                    page === 'report'
                      ? `<div class='iconWapper'>   ${viewIcon} </div>`
                      : `<div class='iconWapper'>   ${editIcon} ${deleteIcon} </div>`;

                  return actionIcons;
                },

                searchable: false,
                orderable: false
              }
            ]
          });

        const table: any = document.querySelector('#customerTable');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#customerTable').DataTable();

          $('#customerTable tbody').on('click', '#view', function () {
            var data = table.row($(this).parents('tr')).data();
            if (data) {
              navigate(`/admin/reports/customers/view-customer`, {
                state: data
              });
            }
          });

          $('#customerTable tbody').on('click', '#edit', function () {
            var data = table.row($(this).parents('tr')).data();
            if (data) {
              navigate(`/admin/customers/update`, {state: data.id});
            }
          });

          $('#customerTable tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            if (data) {
              CustomerDeleteById(data?.id);
            }
          });
        });
      }, 1000);
    }
  };

  const ApplyFilters = async (state: string, city: string) => {
    setLoading(true);
    $('#customerTable').DataTable().destroy();
    if (typeof city === 'undefined') {
      city = '';
    }
    if (state === 'All') {
      state = '';
    }
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `customers/getCustomer?state=${state}&city=${city}`
    });

    if (result) {
      setLoading(false);
      let response = result.data;
      setallCustomers(response);
      const tableData = response.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item.id,
          companyName: item.companyName,
          name: item.custFirstName + ' ' + item.custLastName,
          billAddress: item.billAddress,
          custEmail: item.custEmail,
          custContact: item.custContact,
          custaddress: item.custaddress,
          state: item.state,
          city: item.city,
          zipcode: item.zipcode,
          status: item.status == '1' ? 'Active' : 'In-Active',
          tax: item?.tax || '-'
        };
      });

      setTimeout(() => {
        tableData &&
          $('#customerTable').DataTable({
            data: tableData,
            columns: [
              {data: 'index'},
              {data: 'companyName'},
              {data: 'name'},
              {data: 'custEmail'},
              {data: 'custContact'},
              {data: 'tax'},
              {data: 'custaddress'},
              {data: 'billAddress'},
              {data: 'state'},
              {data: 'city'},
              {data: 'zipcode'},
              {data: 'status'},
              {
                title: 'Action',
                render: function (data, type, row, meta) {
                  const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >`;

                  let editIcon = `<img src="/assets/edit.svg"
                data-toggle="tooltip"
                id="edit"
                data-html="true"
                data-bs-placement="top"
                title="Edit" height="600"
                >`;

                  let actionIcons = `<div class='iconWapper'>   ${editIcon} ${deleteIcon} </div>`;
                  return actionIcons;
                },

                searchable: false,
                orderable: false
              }
            ]
          });

        const table: any = document.querySelector('#customerTable');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#customerTable').DataTable();
          $('#customerTable tbody').on('click', '#edit', function () {
            var data = table.row($(this).parents('tr')).data();
            if (data) {
              navigate(`/admin/customers/update`, {state: data.id});
            }
          });
          $('#customerTable tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            if (data) {
              CustomerDeleteById(data?.id);
            }
          });
        });
      }, 1000);
    }
  };

  const CustomerDeleteById = (id: number) => {
    // $('#customerTable').DataTable().destroy();
    swal({
      title: 'Are you sure ?',
      text: 'You want to delete customer ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false
    }).then(async (response: any) => {
      if (response) {
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'delete',
          url: `customers/delete/${id}`
        });
        if (result) {
          // swal({
          //   text: 'customer deleted successfully',
          //   icon: 'success'
          // });
          navigate(0);
        }
      }
    });
  };

  const PdfGenerate = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(7);

    const rows = allCustomers?.map((item: any, index: any) => {
      return [
        index + 1,
        item.companyName,
        item.custFirstName + ' ' + item.custLastName,
        item.custEmail,
        item.custContact,
        item?.tax || '-',
        item.state,
        item.city,
        item.status == '1' ? 'Active' : 'In-Active'
      ];
    });

    const headers = [
      [
        'S.No.',
        'Company Name',
        'Name',
        'Email',
        'Phone',
        'Tax',
        'State',
        'City',
        'Status'
      ]
    ];

    let content = {
      head: headers,
      body: rows
    };
    // @ts-ignore
    doc.autoTable(content);
    doc.save(`${new Date().toLocaleDateString('en-GB')} Customers Table.pdf`);
  };

  const ExcelGenerate: any = () => {
    const Heading: any = [
      'S.No.',
      'Company Name',
      'Name',
      'Email',
      'Phone',
      'Tax',
      'Ship Address',
      'Bill Address',
      'State',
      'City',
      'Zipcode',
      'Status'
    ];
    const rows: any = allCustomers?.map((item: any, index: any) => {
      return [
        index + 1,
        item.companyName,
        item.custFirstName + ' ' + item.custLastName,
        item.custEmail,
        item.custContact,
        item?.tax || '-',
        item.custaddress,
        item.billAddress,
        item.state,
        item.city,
        item.zipcode,
        item.status == '1' ? 'Active' : 'In-Active'
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString('en-GB')} Customers Table`,
      sheet: `${new Date().toLocaleDateString('en-GB')} Customers Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };

  useEffect(() => {
    $('#customerTable').DataTable().destroy();
    $('#customerTable').find('tbody').empty();

    getAllCustomes();

    return () => {
      $('#customerTable').DataTable().destroy();
      $('#customerTable').find('tbody').empty();
    };
  }, []);

  const stateOptions: any = Object.keys(citiesByState).map((state) => ({
    value: state,
    label: state
  }));

  let updated_array = stateOptions.unshift({
    value: 'All',
    label: 'All'
  });

  const cityOptions = selectedState
    ? citiesByState[selectedState.value]?.map((city: any) => ({
        value: city,
        label: city
      }))
    : [];

  return (
    <>
      {page !== 'report' ? (
        <div className="d-flex justify-content-start flex-wrap gap-3">
          <div style={{flexGrow: '1', maxWidth: '350px'}}>
            <Select
              options={stateOptions}
              isClearable
              onChange={setSelectedState}
              placeholder="Select a state"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '5px'
                })
              }}
            />
          </div>
          <div style={{flexGrow: '1', maxWidth: '350px'}}>
            <Select
              options={cityOptions}
              isClearable
              onChange={setSelectedCity}
              placeholder="Select a city"
              isDisabled={!selectedState}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '5px'
                })
              }}
            />
          </div>
          <div style={{marginLeft: 'auto'}}>
            <AppButton
              children={'Apply Filters'}
              className="btn-lg btn btn-secondary"
              onClick={() => {
                if (selectedState?.value) {
                  ApplyFilters(selectedState.value, selectedCity?.value);
                }
              }}
            />
          </div>
          <div>
            <div className="btn-group">
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-lg dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Export
                </button>

                <div
                  className="dropdown-menu ExportDropdown dropdown-menu-right col-2 dropdownImg"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    className="dropdown-item d-flex flex-row"
                    onClick={() => {
                      ExcelGenerate();
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    <img
                      src="/assets/excel.png"
                      className="mx-2"
                      style={{
                        height: '30px',
                        width: '30px',
                        margin: 0
                      }}
                    />
                    Excel
                  </div>
                  <div className="dropdown-divider"></div>
                  <div
                    onClick={() => {
                      PdfGenerate();
                    }}
                    className="dropdown-item d-flex flex-row "
                    style={{cursor: 'pointer'}}
                  >
                    <img
                      src="/assets/pdf.png"
                      className="mx-2"
                      style={{
                        height: '30px',
                        width: '30px',
                        margin: 0
                      }}
                    />
                    PDF
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {permissionsArray.map((data: any) => {
              return (
                <>
                  {data.title === 'Customers' && data.edit === true && (
                    <div className="mb-3">
                      <AppButton
                        children={'Add New'}
                        className="btn-lg "
                        onClick={() => {
                          navigate('/admin/customers/add');
                        }}
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      ) : null}

      {loading ? (
        <div>
          <div className="d-flex justify-content-center">
            <ScaleLoader color="#007bff" height={40} />
          </div>
        </div>
      ) : (
        <>
          <table id="customerTable" className="table table-hover table-responsive">
            <thead className="thead bg-primary">
              <tr>
                <th>S.No.</th>
                <th>Company Name</th>
                <th>Name </th>
                <th>Email</th>
                <th>Phone</th>
                <th>Tax</th>
                <th>Ship Address</th>
                <th>Bill Address</th>
                <th>State</th>
                <th>City</th>
                <th>Zipcode</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </>
      )}
    </>
  );
};

export default CustomersTable;
