import React from 'react';
import {ContentHeader} from '@components';
import CustomersTable from '@app/components/customers/CustomersTable';

const CustomersReport = () => {
  return (
    <div>
      <ContentHeader title="Sales by Customer" />
      <section className=" bg-white p-4 rounded m-3">
        <CustomersTable page={"report"} />
      </section>
    </div>
  );
};

export default CustomersReport;
