import React, {useState} from 'react';
import {ContentHeader} from '@app/components';
import Invoice from './profile/Invoice';
import {useLocation} from 'react-router-dom';

const CustomerDetails = (props: any) => {
  const [outstandingBalance, setOutStandingBalance] = useState(0);
  const formattedValue = (+outstandingBalance).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  const location: any = useLocation();
  const userData = location.state;
  return (
    <div>
      <ContentHeader title={`Customer Details`} />
      <section className="p-1 rounded m-3">
        <div className="bg-white rounded card-header p-4 shadow-sm">
          <div className="d-flex justify-content-between">
            <h2 className="mb-4 h2 flex-grow-1">
              {userData?.companyName || 'company'}
            </h2>
            <div
              style={{
                border: '2.5px solid #DC3545',
                marginRight: '12px',
                borderRadius: '6px'
              }}
            ></div>
            <div>
              <h5 className="mb-0 text-secondary">Outstanding Balance</h5>
              <h3 className="mt-2 text-dark">
                <strong>{formattedValue}</strong>
              </h3>
            </div>
          </div>
          <div
            className="row align-items-start"
            style={{wordBreak: 'break-all'}}
          >
            <div className="col-2">
              <p className="m-0 text-secondary">Company</p>
              <h6 className="mt-1 text-dark text-wrap h6">
                {userData?.companyName || 'company'}
              </h6>
            </div>
            <div className="col-2">
              <p className="m-0 text-secondary">City</p>
              <h6 className="mt-1 text-dark text-wrap h6">
                {userData?.city || 'city'}
              </h6>
            </div>
          </div>
          <div
            className="row mt-3 align-items-start"
            style={{wordBreak: 'break-all'}}
          >
            <div className="col-2">
              <p className="m-0 text-secondary">Phone</p>
              <h6 className="mt-1 text-dark text-wrap h6">
                {userData?.custContact || 'phone'}
              </h6>
            </div>
            <div className="col-3">
              <p className="m-0 text-secondary">Email</p>
              <h6 className="mt-1 text-dark text-wrap h6">
                {userData?.custEmail || ' email'}
              </h6>
            </div>
            <div className="col-3">
              <p className="m-0 text-secondary">Billing Address</p>
              <h6 className="mt-1 text-dark text-wrap h6">
                {userData?.billAddress || 'address'}
              </h6>
            </div>
          </div>
        </div>
      </section>
      <Invoice
        page={'report'}
        userData={userData}
        setOutStandingBalance={setOutStandingBalance}
      />
    </div>
  );
};
export default CustomerDetails;
