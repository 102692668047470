import {progressLoader} from '@app/store/reducers/ui';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AppButton from '../button/Button';
import ContentHeader from '../content-header/ContentHeader';
import PoshInputs from '../Forms/InputForms/Inputs';
import swal from 'sweetalert';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';

function AddUserRole() {
  const [roleName, setroleName] = useState('');
  const [disable, setdisable] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const permissions_Array = [
    {id: 1, title: 'Dashboard', view: false, edit: false, delete: false},
    {id: 2, title: 'Products', view: false, edit: false, delete: false},
    {id: 3, title: 'Purchase Orders', view: false, edit: false, delete: false},
    {
      id: 4,
      title: 'Sales & Invoices',
      view: false,
      edit: false,
      delete: false
    },
    {id: 5, title: 'Customers', view: false, edit: false, delete: false},
    {id: 6, title: 'Incentives', view: false, edit: false, delete: false},
    {id: 7, title: 'User & Roles', view: false, edit: false, delete: false},
    {id: 8, title: 'Shipping Agents', view: false, edit: false, delete: false},
    {id: 9, title: 'Reports', view: false, edit: false, delete: false}
  ];

  const [savedPermissions, setSavedPermissions] =
    useState<any>(permissions_Array);

  const handleChange = (
    id: number,
    RowIndex: number,
    title: string,
    event: any
  ) => {
    const {checked, name} = event;
    if (id === +permissions_Array[RowIndex].id) {
      setdisable(false);
      const newArr = [...savedPermissions];
      permissions_Array.forEach((data: any) => {
        const allKeys: any = Object.keys(data);

        if (allKeys.includes(name) && data.title === title) {
          if (
            allKeys.find((element: any) => element === 'view') === name &&
            data.title === title
          ) {
            newArr[RowIndex].view = checked;
          }
          if (
            allKeys.find((element: any) => element === 'edit') === name &&
            data.title === title
          ) {
            newArr[RowIndex].edit = checked;
          }
          if (
            allKeys.find((element: any) => element === 'delete') === name &&
            data.title === title
          ) {
            newArr[RowIndex].delete = checked;
          }
        }
      });
      setSavedPermissions(newArr);
    }
    return 'success';
  };

  const addPermissionsRole = async () => {
    if (!disable) {
      await swal({
        title: 'Are you sure ?',
        text: 'You want to add user role ?',
        icon: 'warning',
        buttons: [true, true],
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false
      }).then(async (res) => {
        if (res) {
          const data = {
            roleName: roleName,
            permissions: savedPermissions
          };

          const add_api_response = await apiResponseGenerator({
            dispatchFunction: dispatch,
            method: "post",
            url: "roles/add",
            body: data
          });
          
          if (add_api_response) {
            swal({
              title: `Success`,
              text: `User role added successfully.`,
              icon: 'success',
              closeOnClickOutside: false,
            });
            navigate('/admin/user-and-role/userroles');
          }
        }
      });
    }
  };

  return (
    <div>
      <ContentHeader title="Add User Role" />
      <section className=" bg-white p-4 rounded m-3 mb-3">
        <div className="table-responsive-xxl">
          <div
            className="inputWrapper p-0 col-sm-4 col-12"
            style={{marginLeft: '-7px'}}
          >
            <PoshInputs
              title="Role Name"
              type={'text'}
              onChange={(e: any) => setroleName(e.target.value)}
              readOnly={true}
            />
          </div>

          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col">Title</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">View</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {permissions_Array.map((items: any, index: number) => {
                return (
                  <tr key={index}>
                    <td scope="row">{items.title}</td>
                    <td></td>
                    <td></td>
                    <td>
                      <label className="container d-flex justify-content-center">
                        <input
                          name="view"
                          onChange={(e: any) =>
                            handleChange(items.id, index, items.title, e.target)
                          }
                          value={items.view}
                          type="checkbox"
                        />
                        <div className="checkmark" />
                      </label>
                    </td>

                    <td>
                      <label className="container d-flex justify-content-center">
                        <input
                          name="edit"
                          onChange={(e: any) =>
                            handleChange(items.id, index, items.title, e.target)
                          }
                          value={items.edit}
                          type="checkbox"
                        />
                        <div className="checkmark" />
                      </label>
                    </td>

                    <td>
                      <label className="container d-flex justify-content-center">
                        <input
                          name="delete"
                          onChange={(e: any) =>
                            handleChange(items.id, index, items.title, e.target)
                          }
                          value={items.delete}
                          type="checkbox"
                        />
                        <div className="checkmark" />
                      </label>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-end">
            <AppButton
              children={'Cancel'}
              className="btn btn-light mr-3"
              onClick={() => {
                navigate('/admin/user-and-role/userroles');
              }}
            />
            <AppButton
              children={'Add'}
              disabled={disable}
              onClick={() => {
                addPermissionsRole();
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default AddUserRole;
