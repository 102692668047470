import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import './excelReader.css'; // Import your custom styles

interface RowData {
  cartonNumber: string;
  flavorCategory: string;
  quantity: string;
  weight: string;
  cartonSize: string;
  flavor: string;
  parentCaseBarcode: string;
}

export interface GroupedItem {
  flavorCategory: string;
  flavor: string;
  quantity: number;
  productList: RowData[];
  currentStock?: any;
  flavorsOptions?: any;
}
interface ExcelReaderProps {
  fileState: [
    GroupedItem[],
    React.Dispatch<React.SetStateAction<GroupedItem[]>>
  ];
}

function ExcelReader(props: ExcelReaderProps) {
  const [fileData, setFileData] = props.fileState;
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (event) => {
      if (!event.target?.result) return;

      const binaryString = event.target.result as string;
      const workbook = XLSX.read(binaryString, {type: 'binary'});
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        defval: '' // Ensure empty cells are converted to empty strings
      }) as any[][];

      const categoryIndex = 1; // Column 2 (0-based index) for product category
      const flavorIndex = 5; // Column 6 (0-based index) for product flavor

      const newData: GroupedItem[] = [];

      // Start processing from row 3 (skipping the first two productList)
      for (let i = 2; i < parsedData.length; i++) {
        const row = parsedData[i];
        const flavorCategory = row[categoryIndex] as string; // Use column 2 for product category
        const flavor = row[flavorIndex] as string; // Use column 6 for product flavor

        if (flavorCategory && flavor) {
          // Check if the data is valid before pushing
          const existingItem = newData.find(
            (item) =>
              item.flavorCategory === flavorCategory && item.flavor === flavor
          );

          if (existingItem) {
            existingItem.productList.push({
              cartonNumber: row[0] as string,
              flavorCategory: flavorCategory,
              quantity: row[2] as string,
              weight: row[3] as string,
              cartonSize: row[4] as string,
              flavor,
              parentCaseBarcode: row[6] as string
            });
          } else {
            newData.push({
              flavorCategory,
              flavor: flavor,
              productList: [
                {
                  cartonNumber: row[0] as string,
                  flavorCategory: flavorCategory,
                  quantity: row[2] as string,
                  weight: row[3] as string,
                  cartonSize: row[4] as string,
                  flavor,
                  parentCaseBarcode: row[6] as string
                }
              ],
              quantity: 1 // Initialize quantity for new GroupedItem
            });
          }
        }
      }

      // Calculate and set quantity for each GroupedItem
      newData.forEach((item) => {
        item.quantity = item.productList.length;
      });

      setUploadedFileName(file.name); // Set the uploaded file name
      setFileData(newData);
    };

    reader.readAsBinaryString(file);
  };

  const handleRemoveFile = () => {
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clear the value
    }

    setFileData([]);
    setUploadedFileName(null);
  };

  return (
    <div className="excel-reader-container">
      <div className="file-input-container">
        <input
          type="file"
          id="file-input" // Add this ID attribute
          accept=".xls, .xlsx"
          onChange={handleFileChange}
        />
        <label htmlFor="file-input" className="custom-file-label">
          {uploadedFileName ? uploadedFileName : 'Choose a file'}
        </label>
      </div>
      <button className="upload-button" onClick={handleRemoveFile}>
        Remove File
      </button>
    </div>
  );
}

export default ExcelReader;
