import React, {useCallback, useEffect, useState} from 'react';
import Select from 'react-select';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {ScaleLoader} from 'react-spinners';
import ContentHeader from '@app/components/content-header/ContentHeader';
import AppButton from '@app/components/button/Button';
import '../checkbox/checkbox.css';
import IncentiveModal from '@app/components/Modal/Model';
import swal from 'sweetalert';
import moment from 'moment';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';

const IncentiveAllocationTable = () => {
  const navigate = useNavigate();
  const [Invoice, setInvoices] = useState([]);
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [modalShow, setModalShow] = useState<any>(false);

  const dispatch = useDispatch();
  const [CustId, setCustId] = useState<any>(null);
  const [customerIncentive, setCustomerIncentive] = useState<any>(null);
  const [FilterBtn, setFilterBtn] = useState<boolean>(true);
  const [checkBoxes, setCheckBoxes]:any = useState({});
  const [Previous, setPrevious]: any = useState();
  const [next, setNext]: any = useState();
  const [Pages, setPages]: any = useState([]);
  const [Total, setTotal] = useState<any>(0);
  const [From, setFrom] = useState<any>(0);
  const [To, setTo] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState<boolean>();
  const [ModelRes, setModelRes]: any = useState([]);
  const [Active, setActive]: any = useState();
  const getLocalStorageData: any = JSON.parse(
    localStorage.getItem('Allocation_info') || '{}'
  );

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const getInvoices = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `customers/getAll`
    });
    if (result) {
      setLoading(false);
      const temp = result.data;
      temp.map((element: any) => {
        return (
          (element['label'] = element['companyName']),
          delete element['companyName'],
          (element['value'] = element['id'])
        );
      });
      setInvoices(temp);
    }
  };

  const fetchProductFlavours = async (id: number) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `invoice/getCustomerInvoices/${+id}`
    });
    if (result) {
      let response = result?.data;
      setCustomerIncentive(response.customerIncentive);
      setLoading(false);
      setTotal(response.invoices?.data?.total);
      setFrom(response.invoices?.data?.from);
      setTo(response.invoices?.data?.to);
      setInvoiceData(response.invoices?.data);
      setCurrentPage(response.invoices?.data.current_page);
      let arr = response.invoices?.links;
      arr &&
        arr.forEach((activelink: any) => {
          if (activelink.active) {
            return setActive(activelink.label);
          }
        });
      arr && setPrevious(arr[0]);
      arr && setNext(arr[arr.length - 1]);
      let temp = arr && arr.slice(1, arr.length - 1);
      arr && setPages(temp);
    }
  };

  const nextPage = async (url: string, e: any) => {
    let pagesUrl = url.slice(49);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `${pagesUrl}`
    });
    if (result) {
      setLoading(false);
      setTotal(result.data?.total);
      setFrom(result.data?.from);
      setTo(result.data?.to);
      setInvoiceData(result.data.data);
      setCurrentPage(result.data.current_page);
      let arr = result.data?.links;
      arr.forEach((activelink: any) => {
        if (activelink.active == true) {
          return setActive(activelink.label);
        }
      });
      setPrevious(arr[0]);
      setNext(arr[arr.length - 1]);
      let temp = arr.slice(1, arr.length - 1);
      setPages(temp);
    }
  };

  const GiveIncentive = async () => {
    let result: any[] = ModelRes.map((item: any) => {
      return {id: item?.id};
    });

    let data = {
      customer_id: +CustId,
      invoices: result
    };

    const add_api_response = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: 'invoice/addIncentive',
      body: data
    });
    if (add_api_response) {
      setLoading(false);
      setModalShow(false);
      setInvoiceData([]);
      const result: any = swal({
        title: 'Success',
        text: 'incentive added successfully.',
        icon: 'success',
        closeOnClickOutside: false
      });
      if (result) {
        fetchProductFlavours(selectedOption?.id || getLocalStorageData?.id);
        navigate('/admin/incentives/incentive-allocation');
      }
    }
  };

  useEffect(() => {
    getInvoices();
    if (getLocalStorageData) {
      fetchProductFlavours(getLocalStorageData?.id);
    }
    return () => {
      localStorage.removeItem('Allocation_info');
    };
  }, []);

  useEffect(() => {
    if (selectedOption?.id) {
      setInvoiceData([]);
      fetchProductFlavours(selectedOption?.id);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      setFilterBtn(false);
    } else {
      setFilterBtn(true);
    }
  }, [startDate, endDate]);

  const dateFilter = async (id: number) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `invoice/getCustomerInvoices/${id}?from=${startDate}&to=${endDate}`
    });
    if (result) {
      setLoading(false);
      setTotal(result.data?.total);
      setFrom(result.data?.from);
      setTo(result.data?.to);
      setInvoiceData(result.data.data);
      setCurrentPage(result.data.current_page);
      let arr = result.data?.links;
      arr.forEach((activelink: any) => {
        if (activelink.active == true) {
          return setActive(activelink.label);
        }
      });
      setPrevious(arr[0]);
      setNext(arr[arr.length - 1]);
      let temp = arr.slice(1, arr.length - 1);
      setPages(temp);
    }
  };

  const CheckboxValue = (index:number, valueChange:boolean, item:any) => {
    if (!item.incentive_amount) {
      setCheckBoxes((prevState:any) => ({
        ...prevState,
        [item.id]: true // Update only the specific checkbox state
      }));
      // Show warning and set checkbox to false if no incentive_amount
      swal({
        title: 'Warning',
        text: 'Please add a customer incentive greatter then zero.',
        icon: 'warning',
        closeOnClickOutside: false
      }).then(() => {
        setCheckBoxes((prevState:any) => ({
          ...prevState,
          [item.id]: false // Update only the specific checkbox state
        }));
      });
      return; // Return early
    }

    if (!valueChange) {
      // Checkbox is unchecked
      setCheckBoxes((prevState:any) => ({
        ...prevState,
        [item.id]: valueChange // Update the specific checkbox state
      }));
      const newArr = ModelRes.filter((d:any) => +d.id !== +item?.id);
      setModelRes(newArr);
    } else {
      // Checkbox is checked
      setCheckBoxes((prevState:any) => ({
        ...prevState,
        [item.id]: valueChange // Update the specific checkbox state
      }));
      setCustId(item.CustomerID); // Assuming you have setCustId somewhere
      setModelRes([item, ...ModelRes]);
    }
  };

  const SaveLocalStorage = (id: number,status:string) => {
    localStorage.removeItem('Allocation_info');
    const Allocation_obj: any = {
      id: selectedOption?.id,
      label: selectedOption?.label,
      value: selectedOption?.value,
      status
    };
    if (getLocalStorageData?.id) {
      navigate(`/admin/productsinfo/flavor/${id}`, {
        state: getLocalStorageData
      });
    } else {
      navigate(`/admin/productsinfo/flavor/${id}`, {state: Allocation_obj});
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{marginTop: '20pc', alignItems: 'center'}}
      >
        <ScaleLoader color="#007bff" height={40} />
      </div>
    );
  }

  return (
    <>
      <ContentHeader title="Incentive Allocation" />
      <section className=" bg-white p-4 rounded m-3">
        <div className=" d-flex justify-content-md-between ">
          <div className="col-3" style={{marginLeft: '-7px'}}>
            <Select
              isClearable={!selectedOption ? false : true}
              value={selectedOption || getLocalStorageData}
              onChange={(e: any) => {
                setSelectedOption(e);
              }}
              options={Invoice}
              placeholder="Select Company Name"
            />
          </div>
          <div className="col-3">
            <div className="input-group input-group-md mb-3">
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                className="form-control"
                aria-label="From"
                aria-describedby="inputGroup-sizing-sm"
              />
            </div>
          </div>
          <div className="col-3">
            <div className="input-group input-group-md mb-3">
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="form-control"
                aria-label="From"
                aria-describedby="inputGroup-sizing-sm"
              />
            </div>
          </div>

          <div className="md-col-2">
            <AppButton
              disabled={FilterBtn}
              children={'Apply Filter'}
              onClick={() => {
                dateFilter(selectedOption?.id || getLocalStorageData?.id);
              }}
              className="btn btn-secondary"
            />
          </div>
          <div className="md-col-2">
            <>
              <AppButton
                children={'Calculate Incentive'}
                disabled={ModelRes.length <= 0 ? true : false}
                onClick={() => setModalShow(true)}
              />
            </>
            <IncentiveModal
              show={modalShow}
              modelarray={ModelRes}
              customerIncentive={customerIncentive}
              onHide={() => setModalShow(false)}
              giveIncentive={() => GiveIncentive()}
              title={'Calculate Incentive'}
            />
          </div>
        </div>

        <div className="col-xxl-12 table-responsive">
          <table className="table table-hover" style={{whiteSpace: 'nowrap'}}>
            <thead className="thead- bg-primary">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Company Name</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Invoice #</th>
                <th scope="col">Allocation Date</th>
                <th scope="col">Total Cases</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Incentive</th>
                <th scope="col">Status</th>
                <th scope="col">Incentive status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData?.map((item: any, index: number) => {
                if (currentPage > 1) {
                  index = (currentPage - 1) * 10 + index + 1;
                } else {
                  index = index;
                }

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.companyName}</td>
                    <td>
                      {item.custFirstName} {item.custLastName}
                    </td>
                    <td>{item.id}</td>
                    <td>{moment(item.created_at).format('MM/DD/YYYY')}</td>
                    <td>{item.total_cases}</td>
                    <td>{item.grand_total}</td>
                    <td>{item.incentive_amount}</td>
                    <td>{item.status}</td>
                    <td>{item.is_incentive_given ? 'Calculated' : 'Pending'}</td>
                    <td className="iconWapper">
                      <div className="d-flex justify-content-center">
                        {!item.is_incentive_given && (
                          <label className="container d-flex justify-content-center">
                            <input
                             checked={checkBoxes[item.id] || false} // Use checked instead of defaultChecked
                              onChange={(e) => CheckboxValue(index, e.target.checked, item)}
                              type="checkbox"
                            />
                            <div className="checkmark" />
                          </label>
                        )}
                        <img
                          src="/assets/eye.svg"
                          data-toggle="tooltip"
                          data-html="true"
                          onClick={() => {
                            SaveLocalStorage(item.id,item.status);
                          }}
                          data-bs-placement="top"
                          title="View"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {Total > 10 ? (
          <nav
            aria-label="Page navigation example"
            className="d-flex justify-content-between"
            style={{marginTop: 10}}
          >
            <div>
              <p>
                Showing {From} to {To} of <b>{Total}</b> Allocations
              </p>
            </div>
            <ul className="pagination  justify-content-end">
              <li className="page-item">
                <button
                  disabled={Previous?.url == null ? true : false}
                  className="page-link page-item"
                  onClick={(e: any) => {
                    nextPage(Previous.url, e);
                  }}
                >
                  Previous
                </button>
              </li>

              {Pages.map((item: any, index: number) => {
                let temp = (index += 1);
                let url = item.url;
                return (
                  <li
                    onClick={(e) => nextPage(url, e)}
                    key={index}
                    className={`page-item ${+Active === temp ? 'active' : ''}`}
                  >
                    <a className="page-link" href="#">
                      {temp}
                    </a>
                  </li>
                );
              })}

              <li className="page-item">
                <button
                  disabled={next?.url != null ? false : true}
                  onClick={(e) => {
                    nextPage(next.url, e);
                  }}
                  className="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        ) : (
          ' '
        )}
      </section>
    </>
  );
};

export default IncentiveAllocationTable;
