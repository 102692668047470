import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import EditInvoiceTable from '@app/components/Tables/EditInvoiceTable';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {ScaleLoader} from 'react-spinners';
import swal from 'sweetalert';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import {useParams} from 'react-router-dom';

const EditInvoice = () => {
  const [products, setProducts] = useState([]);
  const [halfName, setHalfName]: any = useState([]);
  const [productFlavor, setProductFlavor] = useState([]);
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productPrice, setProductPrice] = useState('');
  const [InvoiceData,setInvoiceData] = useState<any>(null)
  const getParams = useParams();
  const dispatch = useDispatch() 

  const selectDisable = useSelector((state: any) => {
    return state.ui.SalesProductSelect_disable;
  });

  const handelChange = async (e: Event) => {
    if (selectedOption) {
      const model = await swal({
        title: 'Are your sure?',
        text: 'You want to change the product? your all unsaved data will be lost',
        icon: 'warning',
        buttons: [true, true],
        closeOnClickOutside: false,
      });
      if (model) {
        setSelectedOption(e);
      }
    } else {
      setSelectedOption(e);
    }
  };

  const getProducts = async () => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `invoice/getProducts/${getParams.id}`
    });
    setLoading(false);

    if (!!!result) return;
    const response = result.data;
    response.map((element: any) => {
      return (
        (element['label'] = element['productName']),
        delete element['productName'],
        (element['value'] = element['product_id'])
      );
    });
    setProducts(response);
  };

  const fetchProductDetails = async (id: number) => {
    setHalfName([]);
    let productFlavourData = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `productflavors/${id}`
    });

    let invoiceProductSalesData = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: `sales/getSalesByProduct`,
      body: {
        invoice_id: getParams.id,
        product_id: id
      }
    });

    if (productFlavourData) {
      productFlavourData = productFlavourData.data.product;
      // get the product info
      setProductFlavor(productFlavourData);

      // save the product price
      setProductPrice(productFlavourData[0]?.price);
      
      // get the short code of flavor
      const shortCodes = productFlavourData.map((item: any) => {
        return item?.ShortCode;
      });
      setHalfName(shortCodes);
    }

    if (invoiceProductSalesData) {
      invoiceProductSalesData = invoiceProductSalesData.data;
      console.log({invoiceProductSalesData})
      setInvoiceData(invoiceProductSalesData.salesData.sales)
      setCustomers(invoiceProductSalesData.salesData.customer);
    }
    return 'success';
  };
  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (+selectedOption?.value) {
      fetchProductDetails(+selectedOption.value);
    }
  }, [selectedOption]);

  return loading ? (
    <div>
      <ContentHeader title="Edit The Invoice" />
      <div
        className="d-flex justify-content-center"
        style={{marginTop: '250px'}}
      >
        <ScaleLoader color="#007bff" height={40} />
      </div>
    </div>
  ) : (
    <div>
      <ContentHeader title={`Edit The Invoice (#${getParams.id})`} />
      <section className="bg-white p-4 rounded m-3" style={{maxHeight: '80%'}}>
        <div>
          <div className="col-4" style={{marginLeft: '-0.5pc'}}>
            <Select
              value={selectedOption}
              onChange={(e: any) => handelChange(e)}
              options={products}
            />
          </div>
        </div>

        <div>
          <EditInvoiceTable
            halfName={halfName}
            productPrice={productPrice}
            customerData={customers}
            productFlavor={productFlavor}
            productInfo={selectedOption}
            invoiceData={InvoiceData}
          />
        </div>
      </section>
    </div>
  );
};

export default EditInvoice;
