import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import swal from 'sweetalert';
import AppButton from '@app/components/button/Button';
import {ScaleLoader} from 'react-spinners';
import {downloadExcel} from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import moment from 'moment';
import Select from 'react-select';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

function Invoice(props: any) {
  const page = props.page;
  const userData = props.userData;
  const setOutStandingBalance = props.setOutStandingBalance;

  const param: any = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);
  const [InvoiceData, setInvoiceData] = useState([]);
  const [loading, setLoading] = useState<boolean>();

  const [filterBtnDisable, setFilterBtnDisabled] = useState<any>(true);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);

  let local: any = localStorage.getItem('userInfo');
  const permissionsArray: any = JSON.parse(local).permissions;
  let navigate = useNavigate();

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  };

  useEffect(() => {
    $('#invoiceTable').DataTable().destroy();
    $('#invoiceTable').find('tbody').empty();

    getInvoices();

    return () => {
      $('#invoiceTable').DataTable().destroy();
      $('#invoiceTable').find('tbody').empty();
    };
  }, []);

  useEffect(() => {
    // Check if both startDate and endDate are selected, or if only status is selected
    const isFilterValid =
      (startDate && endDate || !startDate && !endDate ) ||
      ((status || status === ''));

    setFilterBtnDisabled(!isFilterValid);
  }, [startDate, endDate, status]);

  const handleInputEmail = async (id: number) => {
    const response = await swal({
      title: 'Add invoice no',
      content: {
        element: 'input',
        attributes: {
          type: 'text',
          placeholder: 'Enter your invoice no'
        }
      },
      buttons: {
        confirm: {
          text: 'OK',
          closeModal: true
        },
        cancel: true
      },
      closeOnEsc: false,
      closeOnClickOutside: false
    });

    if (response) {
      const api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: `invoice/addInvoiceNo/${id}`,
        body: {invoice_id: response}
      });
      if (api_response) {
        const result = await swal({
          title: 'Success',
          text: 'Invoice no added successfully.',
          icon: 'success'
        });
        if (result) {
          navigate(0);
        }
      }
    }
  };

  const getInvoices = async () => {
    setLoading(true);

    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url:
        page === 'report'
          ? `report/salesByCustomers/${userData.id}`
          : 'invoice/getAll'
    });

    let response: any = null;
    let tableData: any = null;

    if (result && page === 'report') {
      setLoading(false);

      response = result.data;
      if (response.outstanding_balance) {
        setOutStandingBalance(response.outstanding_balance);
      }

      tableData = response?.customer?.invoice.map(
        (item: any, index: number) => {
          const formattedValue = (+item.total_amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          });
          return {
            index: index + 1,
            id: item.id,
            invoice_id: item.invoice_id,
            Allocation_Date: moment(item.created_at).format('MM/DD/YYYY'),
            Total_Cases: item?.sales[0].total_cases || '',
            Total_price: formattedValue,
            Status: item.status === 'Completed' ? 'Closed' : item.status
          };
        }
      );
      setInvoiceData(tableData);
    }

    if (result && page !== 'report') {
      setLoading(false);

      response = result.data;

      tableData = response.map((item: any, index: number) => {
        const formattedValue = (+item.total_amount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return {
          index: index + 1,
          id: item.id,
          Company_Name: item.companyName,
          invoice_id: item.invoice_id,
          Allocation_Date: moment(item.created_at).format('MM/DD/YYYY'),
          Total_Cases: item.total_cases,
          Total_price: formattedValue.includes('$NaN')
          ? item.total_amount
          : formattedValue,
          Status: item.status === 'Completed' ? 'Closed' : item.status
        };
      });
      setInvoiceData(tableData);
    }

    if (response && tableData) {
      $('#invoiceTable').DataTable().destroy();
      $('#invoiceTable').find('tbody').empty();

      let tableCloumns =
        page === 'report'
          ? [
              {data: 'index'},
              {data: 'id'},
              {data: 'invoice_id'},
              {data: 'Allocation_Date'},
              {data: 'Total_Cases'},
              {data: 'Total_price'},
              {data: 'Status'}
            ]
          : [
              {data: 'index'},
              {data: 'Company_Name'},
              {data: 'id'},
              {data: 'invoice_id'},
              {data: 'Allocation_Date'},
              {data: 'Total_Cases'},
              {data: 'Total_price'},
              {data: 'Status'}
            ];

      setTimeout(() => {
        $('#invoiceTable').DataTable({
          // responsive: true,
          data: tableData,
          columns: [
            ...tableCloumns,
            {
              title: 'Action',
              render: function (data, type, row, meta) {
                const viewIcon = `
                <img src="/assets/eye.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="view"
                 data-bs-placement="top"
                 title="View" height="600"
                 >`;

                const addIcon = `
                <img src="/assets/add.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="add"
                 data-bs-placement="top"
                 title="Add" height="600"
                 >`;

                const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >`;

                let editIcon = '';

                if (row.Status && row.Status.toLowerCase() !== 'generated') {
                  editIcon = `<img src="/assets/edit.svg"
                  data-toggle="tooltip"
                  id="edit"
                  data-html="true"
                  data-bs-placement="top"
                  title="Edit" height="600"
                  >`;
                }

                let actionIcons = `${
                  page === 'report'
                    ? `<div class='iconWapper'>${viewIcon}</div>`
                    : `<div class='iconWapper'> ${addIcon} ${viewIcon} ${deleteIcon} ${editIcon} </div>`
                }
                `;
                return actionIcons;
              },

              searchable: false,
              orderable: false
            }
          ]
        });

        const table: any = document.querySelector('#invoiceTable');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          let table = $('#invoiceTable').DataTable();
          $('#invoiceTable tbody').on('click', '#add', function () {
            let data = table.row($(this).parents('tr')).data();
            handleInputEmail(+data.id);
          });
          $('#invoiceTable tbody').on('click', '#view', function () {
            let data = table.row($(this).parents('tr')).data();

            if (data) {
              const obj = {
                id: +data.id,
                invoice_id: +data?.invoice_id,
                state: true,
                status: data.Status
              };
              navigate(`/admin/productsinfo/flavor/${+data.id}`, {state: obj});
            }
          });

          $('#invoiceTable tbody').on('click', '#edit', function () {
            let data = table.row($(this).parents('tr')).data();
            navigate(`/admin/edit-invoice/${+data.id}`);
          });

          $('#invoiceTable tbody').on('click', '#delete', function () {
            let data = table.row($(this).parents('tr')).data();
            data.Status === 'Generated'
              ? deleteInvoice(+data.id)
              : RevertTheInvoice(+data.id);
          });
        });
      }, 1000);
    }
  };

  const dateFilter = async () => {
    if (!startDate && !endDate) {
      getInvoices();
      return;
    }

    let URL = '';

    if (status === '' && !startDate && !endDate) {
      URL = `report/salesByCustomers/${userData.id}`;
    }

    if (startDate && endDate && status && page === 'report') {
      URL = `report/salesByCustomers/${userData.id}?from=${startDate}&to=${endDate}&status=${status}`;
    }

    if (startDate && endDate && !status && page === 'report') {
      URL = `report/salesByCustomers/${userData.id}?from=${startDate}&to=${endDate}`;
    }

    if (!startDate && !endDate && status && page === 'report') {
      URL = `report/salesByCustomers/${userData.id}?status=${status}`;
    }

    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      url:
        page === 'report'
          ? URL
          : `invoice/search?from=${startDate}&to=${endDate}`,
      method: 'get'
    });

    let response: any = null;
    let tableData: any = null;

    if (result && page === 'report') {
      setLoading(false);

      response = result?.data;

      tableData = response?.customer?.invoice.map(
        (item: any, index: number) => {
          const formattedValue = (+item.total_amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          });
          return {
            index: index + 1,
            id: item.id,
            invoice_id: item.invoice_id,
            Allocation_Date: moment(item.created_at).format('MM/DD/YYYY'),
            Total_Cases: item?.total_cases || '',
            Total_price: formattedValue,
            Status: item.status === 'Completed' ? 'Closed' : item.status
          };
        }
      );
      setInvoiceData(tableData);
    }

    if (result && page !== 'report') {
      setLoading(false);

      response = result.data;

      tableData = response.map((item: any, index: number) => {
        const formattedValue = (+item.total_amount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return {
          index: index + 1,
          id: item.id,
          Company_Name: item.companyName,
          invoice_id: item.invoice_id,
          Allocation_Date: moment(item.created_at).format('MM/DD/YYYY'),
          Total_Cases: item.total_cases,
          Total_price: formattedValue.includes('$NaN')
          ? item.total_amount
          : formattedValue,
          Status: item.status === 'Completed' ? 'Closed' : item.status
        };
      });
      setInvoiceData(tableData);
    }

    if (response && tableData) {
      $('#invoiceTable').DataTable().destroy();
      $('#invoiceTable').find('tbody').empty();

      let tableCloumns =
        page === 'report'
          ? [
              {data: 'index'},
              {data: 'id'},
              {data: 'invoice_id'},
              {data: 'Allocation_Date'},
              {data: 'Total_Cases'},
              {data: 'Total_price'},
              {data: 'Status'}
            ]
          : [
              {data: 'index'},
              {data: 'Company_Name'},
              {data: 'id'},
              {data: 'invoice_id'},
              {data: 'Allocation_Date'},
              {data: 'Total_Cases'},
              {data: 'Total_price'},
              {data: 'Status'}
            ];

      setTimeout(() => {
        $('#invoiceTable').DataTable({
          // responsive: true,
          data: tableData,
          columns: [
            ...tableCloumns,
            {
              title: 'Action',
              render: function (data, type, row, meta) {
                const viewIcon = `
                <img src="/assets/eye.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 class="view"
                 data-bs-placement="top"
                 title="View" height="600"
                 >`;

                const addIcon = `
                <img src="/assets/add.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 class="add"
                 data-bs-placement="top"
                 title="Add" height="600"
                 >`;

                const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                class="delete"
                title="Delete" height="600"
                >`;

                let editIcon = '';

                if (row.Status && row.Status.toLowerCase() !== 'generated') {
                  editIcon = `<img src="/assets/edit.svg"
                  data-toggle="tooltip"
                  class="edit"
                  data-html="true"
                  data-bs-placement="top"
                  title="Edit" height="600"
                  >`;
                }

                let actionIcons = `${
                  page === 'report'
                    ? `<div class='iconWapper'>${viewIcon}</div>`
                    : `<div class='iconWapper'> ${addIcon} ${viewIcon} ${deleteIcon} ${editIcon} </div>`
                }
                `;
                return actionIcons;
              },

              searchable: false,
              orderable: false
            }
          ]
        });

        const table: any = document.querySelector('#invoiceTable');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          let table = $('#invoiceTable').DataTable();
          $('#invoiceTable tbody').on('click', '.add', function () {
            let data = table.row($(this).parents('tr')).data();
            handleInputEmail(+data.id);
          });
          $('#invoiceTable tbody').on('click', '.view', function () {
            let data = table.row($(this).parents('tr')).data();

            if (data) {
              const obj = {
                id: +data.id,
                status: data.Status,
                invoice_id: +data?.invoice_id,
                state: true
              };
              navigate(`/admin/productsinfo/flavor/${+data.id}`, {state: obj});
            }
          });

          $('#invoiceTable tbody').on('click', '.edit', function () {
            let data = table.row($(this).parents('tr')).data();
            navigate(`/admin/edit-invoice/${+data.id}`);
          });

          $('#invoiceTable tbody').on('click', '.delete', function () {
            let data = table.row($(this).parents('tr')).data();
            data.Status === 'Generated'
              ? deleteInvoice(+data.id)
              : RevertTheInvoice(+data.id);
          });
        });
      }, 1000);
    }
  };

  const PdfGenerate = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(20);

    const rows = InvoiceData?.map((item: any, index: any) => {
      return [
        item.index,
        userData.companyName ? userData.companyName : item.Company_Name,
        item.id,
        item.invoice_id,
        item.Allocation_Date,
        item.Total_Cases,
        item.Total_price,
        item.Status
      ];
    });

    const headers = [
      [
        'S.No.',
        'Company Name',
        'Grn #',
        'Invoice #',
        'Allocation Date',
        'Total Cases',
        'Total Price',
        'Status'
      ]
    ];
    let content = {
      startY: 60,
      head: headers,
      body: rows
    };
    // @ts-ignore
    doc.autoTable(content);
    doc.save(`${new Date().toLocaleDateString('en-GB')} Invoice Table.pdf`);
  };

  const ExcelGenerate: any = () => {
    const Heading: any = [
      'S.No.',
      'Company Name',
      'Grn #',
      'Invoice #',
      'Allocation Date',
      'Total Cases',
      'Total Price',
      'Status'
    ];
    const rows: any = InvoiceData?.map((item: any, index: any) => {
      return [
        item.index,
        userData.companyName ? userData.companyName : item.Company_Name,
        item.id,
        item.invoice_id,
        item.Allocation_Date,
        item.Total_Cases,
        item.Total_price,
        item.Status
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString('en-GB')} Invoice Table`,
      sheet: `${new Date().toLocaleDateString('en-GB')} Invoice Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };

  const RevertTheInvoice = async (id: number) => {
    const model = await swal({
      title: 'Are you sure ?',
      text: 'You want to do the invoice reversal ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false
    });

    if (model) {
      const result = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'delete',
        url: `invoice/reversal/${id}`
      });

      if (!result) return;
      swal({
        title: 'Success',
        text: 'Invoice reversed successfully.',
        icon: 'success'
      });

      $('#invoiceTable').DataTable().destroy();
      getInvoices();
    }
  };

  const deleteInvoice = async (id: number) => {
    const model = await swal({
      title: 'Are you sure ?',
      text: `Stock will be updated when you delete the invoice.
             You want to do the invoice reversal ?`,
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false
    });

    if (model) {
      const result = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'delete',
        url: `invoice/delete/${id}`
      });

      if (!result) return;

      swal({
        text: 'invoice deleted successfully',
        icon: 'success'
      });

      $('#invoiceTable').DataTable().destroy();
      getInvoices();
    }
  };

  const options = [
    {value: '', label: 'All'},
    {value: '0', label: 'Generated'},
    {value: '1', label: 'Closed'},
    {value: '2', label: 'Dispatached'}
  ];

  return (
    <div>
      <>
        {page !== 'report' ? <ContentHeader title="Invoices" /> : null}

        <section className=" bg-white p-4 rounded m-3">
          <div>
            <div className="row mb-2 d-flex  col-lg-justify-content-between">
              <div className="col-xl-3">
                <div className="input-group input-group-lg mb-3">
                  <input
                    value={startDate}
                    type="date"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setStartDate(null);
                      } else {
                        setStartDate(e.target.value);
                      }
                    }}
                    className="form-control"
                    aria-label="From"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="input-group input-group-lg mb-3">
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setEndDate(null);
                      } else {
                        setEndDate(e.target.value);
                      }
                    }}
                    className="form-control"
                    aria-label="From"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
              </div>
              {page === 'report' ? (
                <div className="col-xl-3 mb-3">
                  <Select
                    options={options}
                    isClearable
                    onChange={(selectedOption) =>
                      setStatus(selectedOption?.value)
                    }
                    placeholder="Select Status"
                    isDisabled={false}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '5px'
                      })
                    }}
                  />
                </div>
              ) : null}

              <div
                className="col d-flex justify-content-end"
                style={{left: '13px'}}
              >
                <AppButton
                  children={'Apply Filter'}
                  disabled={filterBtnDisable}
                  className="btn-lg mb-3 mr-3 btn btn-secondary "
                  onClick={() => {
                    $('#invoiceTable').DataTable().destroy();
                    $('#invoiceTable').find('tbody').empty();
                    dateFilter();
                  }}
                />
                <div className="btn-group">
                  <div className="dropdown">
                    <button
                      className="btn btn-dark mr-3 btn-lg mb-3  dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Export
                    </button>

                    <div
                      className="dropdown-menu col-2 ExportDropdown dropdownImg"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        className="dropdown-item d-flex flex-row"
                        onClick={() => {
                          ExcelGenerate();
                        }}
                        style={{cursor: 'pointer'}}
                      >
                        <img
                          src="/assets/excel.png"
                          className="mx-2"
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: 0
                          }}
                        />
                        Excel
                      </div>
                      <div className="dropdown-divider"></div>
                      <div
                        onClick={() => {
                          PdfGenerate();
                        }}
                        className="dropdown-item d-flex flex-row "
                        style={{cursor: 'pointer'}}
                      >
                        <img
                          src="/assets/pdf.png"
                          className="mx-2"
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: 0
                          }}
                        />
                        PDF
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div>
              <div className="d-flex justify-content-center">
                <ScaleLoader color="#007bff" height={40} />
              </div>
            </div>
          ) : (
            <>
              <table id="invoiceTable" className="table table-hover">
                <thead className="thead- bg-primary">
                  <tr>
                    <th scope="col">S.No.</th>
                    {page === 'report' ? null : (
                      <th scope="col">Company Name</th>
                    )}
                    <th scope="col">Grn #</th>
                    <th scope="col">Invoice #</th>
                    <th scope="col">Allocation Date</th>
                    <th scope="col">Total Cases</th>
                    <th scope="col">Total Price</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
              </table>
            </>
          )}
        </section>
      </>
    </div>
  );
}

export default Invoice;
