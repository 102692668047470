import React from 'react';
import './customLoader.css'; // Import your custom loader styles

interface CustomLoaderProps {
  show: boolean;
}

const CustomLoader: React.FC<CustomLoaderProps> = ({ show }) => {
  return (
    <div className={`custom-loader-container${show ? ' active' : ''}`}>
      <div className="custom-loader">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default CustomLoader;
