
import React, { useEffect, useState } from 'react';
import {ContentHeader} from '@components';
import Shippingagent from '@app/components/Shipping-agents/ShippingAgent';
import axios from 'axios';
import { BASE_URL } from '@app/components/constants/app';
import { useDispatch, useSelector } from 'react-redux';
import { progressLoader, setLoading } from '@app/store/reducers/ui';
import { ScaleLoader } from 'react-spinners';


const ShippingAgent = () => { 
  const[agents,setAgents]=useState([])
  const [Previous,setPrevious]:any=useState()
  const [next,setNext]:any=useState()
  const [Pages,setPages]:any=useState([])
  const [currentPage,setCurrentPage]:any=useState(0)
  const token=useSelector((state:any)=>state.auth.token)
  const [search,setSearch]=useState("")
  const dispatch=useDispatch()
  const [Total, setTotal] = useState<any>()
  const [From, setFrom] = useState<any>()
  const [To, setTo] = useState<any>()
  const [loading, setloading] = useState(true)
  const [Active, setActive]:any = useState()


  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getAgents=async()=>{
    setloading(true)
    dispatch(setLoading(true))
    const fetch=await axios({
      url:`${BASE_URL}agents/get`,
      headers:config,
      onDownloadProgress(progressEvent: { loaded: number; total: number; }) {
        let progressB = (progressEvent.loaded / progressEvent.total) * 100;
        dispatch(progressLoader(progressB))
      },
      onUploadProgress:progressEvent => {
        let progressB = (progressEvent.loaded / progressEvent.total) * 100;
        dispatch(progressLoader(progressB))
      }
    })
    try {
      if(fetch.data.meta.statusCode==200){
        setloading(false)
        setTotal(fetch.data.data?.total)
        setFrom(fetch.data.data?.from)
        setTo(fetch.data.data?.to)
        setAgents(fetch.data.data?.data)
        let arr= fetch.data.data?.links
        arr.forEach((activelink:any) => {
          if(activelink.active == true) {
            return setActive(activelink.label)
           }
        })
        setCurrentPage(fetch.data?.data?.current_page)
        setPrevious(arr[0])
        setNext(arr[arr.length-1])
        let temp=arr.slice(1,arr.length-1)
        setPages(temp)
      }
    } catch (error) {
      
    }
  }
  const filter=async()=>{
    setloading(true)
    let res=await axios({
      url:`${BASE_URL}agents/search`,
      headers:config,
      params:{
        search:search
      },
      method:"get",
      onDownloadProgress(progressEvent: { loaded: number; total: number; }) {
        let progressB = (progressEvent.loaded / progressEvent.total) * 100;
        dispatch(progressLoader(progressB))
      },
      onUploadProgress:progressEvent => {
        let progressB = (progressEvent.loaded / progressEvent.total) * 100;
        dispatch(progressLoader(progressB))
      }
    })
      if(res){
        setloading(false)
        setAgents(res.data.data?.data.reverse())
        let arr= res.data.data?.links
        arr.forEach((activelink:any) => {
          if(activelink.active == true) {
            return setActive(activelink.label)
           }
        })
        setTotal(res.data.data?.total)
        setFrom(res.data.data?.from)
        setTo(res.data.data?.to)
        setCurrentPage(res.data?.data?.current_page)
        setPrevious(arr[0])
        setNext(arr[arr.length-1])
        let temp=arr.slice(1,arr.length-1)
        setPages(temp)
      }
 
    
  }

  const nextPage=async(url:string,e:any)=>{
    // setloading(true)
    dispatch(progressLoader(5))
    const finalUrl = `${url}&search=${search}`
    e.preventDefault()
      try {
        axios({
        url:finalUrl,
        headers:config,
        timeout:1000*5,
        onDownloadProgress(progressEvent) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB))
        },
        onUploadProgress:progressEvent => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB))
        }
      }).then((res)=>{    
        setloading(false)
        if(res.data.meta.statusCode==200){
          setTotal(res.data.data?.total)
          setFrom(res.data.data?.from)
          setTo(res.data.data?.to)
          setCurrentPage(res.data?.data?.current_page)
          setAgents(res.data.data?.data.reverse())

          let arr= res.data.data?.links
          arr.forEach((activelink:any) => {
            if(activelink.active == true) {
              return setActive(activelink.label)
             }
          })
          setPrevious(arr[0])
          setNext(arr[arr.length-1])
          
        } 
      }).catch((e)=>{
        console.log("get cutomer catch ",e);
      })
      } catch (error) {
          console.log("next page api error" ,error);
      }
  }


  useEffect(()=>{
    if(search.length>3){
      filter()
    }
    if(search === ""){
      getAgents()
    }
  },[search])
  
 

  useEffect(()=>{
    getAgents()
  },[])
  
  const tableHeadings=[{value:"S.No."},{value:"Name"},{value:"Email"},{value:"Phone"},{value:"Address"},{value:"Status"},{value:"Action"}]

  return (
    <div>
      <ContentHeader title="Shipping  Agents" />
      <section className=" bg-white p-4 rounded m-3">
        <Shippingagent 
          loading={loading} 
          pageClick={nextPage} 
          Total={Total} 
          To={To} 
          From={From} 
          page={Pages} 
          current={currentPage} 
          next={next} 
          previous={Previous}   
          onChange={(e:any)=>{setSearch(e.target.value)}}  
          getAgents={getAgents} 
          tableData={agents}  
          tableHeading={tableHeadings} 
          Active={Active}
        />
      </section>
    </div>
  );
};

export default ShippingAgent;
