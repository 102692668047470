import {useNavigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import '././../Flavors/Tableinput.css';
import AppButton from '../button/Button';
import {useDispatch} from 'react-redux';
import {ScaleLoader} from 'react-spinners';
import exportPDF from '../ExportPdf&Excel/PdfGeneratorItemsTable';
import swal from 'sweetalert';
import {downloadExcel} from 'react-export-table-to-excel';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import moment from 'moment';

const ItemsTable = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllproducts] = useState([]);
  let local: any = localStorage.getItem('userInfo');
  const permissionsArray = JSON.parse(local).permissions;

  const getAllProducts = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'products/getAll'
    });
    if (result) {
      setLoading(false);
      const tableData = result.data.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item?.id,
          productSKU: item?.productSKU,
          productName: item?.productName,
          volume: item?.volume,
          created_at: moment(item?.created_at).format('MM/DD/YYYY'),
          status: item?.status == 1 ? 'Active' : 'In-Active'
        };
      });

      setTimeout(() => {
        console.log({tableData});
        tableData &&
          $('#itemsTable').DataTable({
            data: tableData,
            columns: [
              {data: 'index'},
              {data: 'productSKU'},
              {data: 'productName'},
              {data: 'volume'},
              {data: 'created_at'},
              {data: 'status'},
              {
                title: 'Action',
                render: function (data, type, row, meta) {
                  const viewIcon = `
                <img src="/assets/eye.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="veiw"
                 data-bs-placement="top"
                 title="View" height="600"
                 >`;

                  const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >`;

                  let editIcon = `<img src="/assets/edit.svg"
                data-toggle="tooltip"
                id="edit"
                data-html="true"
                data-bs-placement="top"
                title="Edit" height="600"
                >`;

                  let actionIcons = `<div class='iconWapper'> ${viewIcon} ${deleteIcon} ${editIcon} </div>`;
                  return actionIcons;
                },
                searchable: false,
                orderable: false
              }
            ]
          });

        const table: any = document.querySelector('#itemsTable');
        console.log({table});
        table && table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#itemsTable').DataTable();
          $('#itemsTable tbody').on('click', '#veiw', function () {
            var data = table.row($(this).parents('tr')).data();
            console.log('delete data: ' + data);
            navigate(`/admin/products/categories/veiw/${data?.id}`);
          });
          $('#itemsTable tbody').on('click', '#edit', function () {
            var data = table.row($(this).parents('tr')).data();
            console.log('delete data: ' + data);
            navigate(`/admin/products/categories/edit/${data?.id}`);
          });
          $('#itemsTable tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            console.log('delete data: ' + data);
            modalDelete(data?.id);
          });
        });
      }, 1000);

      setAllproducts(
        result.data?.map((item: any) => {
          return {
            id: item.id,
            productSKU: item.productSKU,
            productName: item.productName,
            productBarcode: item.productBarcode,
            volume: item.volume,
            created_at: item.created_at,
            status: item.status === 1 ? 'Active' : 'In Active'
          };
        })
      );
    }
  };

  const modalDelete = (id: number) => {
    // $('#itemsTable').DataTable().destroy();
    swal({
      title: 'Are you sure ?',
      text: 'You want to delete product category ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false
    }).then(async (response: any) => {
      if (response) {
        setLoading(false);
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'delete',
          url: `products/delete/${id}`
        });
        if (result) {
          navigate(0);
          // swal({
          //   title: 'Success',
          //   text: 'product category deleted successfully.',
          //   icon: 'success'
          // });
          // getAllProducts();
        }
      }
    });
  };

  useEffect(() => {
    $('#itemsTable').DataTable().destroy();
    $('#itemsTable').find('tbody').empty();

    getAllProducts();

    return () => {
      $('#itemsTable').DataTable().destroy();
      $('#itemsTable').find('tbody').empty();
    };
  }, []);

  const onDownload: any = () => {
    const Heading: any = [
      'S.No.',
      'SKU',
      'Product Category',
      'Volume',
      'Created at',
      'Status'
    ];
    const rows: any = allProducts.map((item: any, index: any) => {
      return [
        index + 1,
        item.productSKU,
        item.productName,
        item.volume,
        moment(item?.created_at).format('MM/DD/YYYY'),
        item.status
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString(
        'en-GB'
      )} Product Category Table`,
      sheet: `${new Date().toLocaleDateString('en-GB')} Product Category Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };

  return (
    <section className=" bg-white p-4 rounded m-3">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="d-flex justify-content-end">
            <div className="btn-group">
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-lg mb-3 mr-3 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Export
                </button>

                <div
                  className="dropdown-menu ExportDropdown col-2 dropdownImg"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    className="dropdown-item d-flex flex-row"
                    onClick={() => {
                      onDownload();
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    <img
                      src="/assets/excel.png"
                      className="mx-2"
                      style={{
                        height: '30px',
                        width: '30px',
                        margin: 0
                      }}
                    />
                    Excel
                  </div>
                  <div className="dropdown-divider"></div>
                  <div
                    onClick={(e) => {
                      exportPDF(allProducts);
                    }}
                    className="dropdown-item d-flex flex-row "
                    style={{cursor: 'pointer'}}
                  >
                    <img
                      src="/assets/pdf.png"
                      className="mx-2"
                      style={{
                        height: '30px',
                        width: '30px',
                        margin: 0
                      }}
                    />
                    PDF
                  </div>
                </div>
              </div>
            </div>
            <AppButton
              children={'Add New'}
              className="btn btn-primary btn-lg mb-3 "
              onClick={() => {
                navigate('/admin/products/categories/add');
              }}
            />
          </div>
        </div>
        <div className="MainDiv">
          <div className="container-fluid">
            {loading ? (
              <div>
                <div className="d-flex justify-content-center">
                  <ScaleLoader color="#007bff" height={40} />
                </div>
              </div>
            ) : (
              <table id="itemsTable" className="table table-hover">
                <thead className="bg-primary">
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Product Category</th>
                    <th scope="col">Volume</th>
                    <th scope="col">Created at</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ItemsTable;
