import {useNavigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import AppButton from '../button/Button';
import swal from 'sweetalert';
import axios from 'axios';
import {BASE_URL} from '../constants/app';
import {useDispatch, useSelector} from 'react-redux';
import {progressLoader, setLoading} from '@app/store/reducers/ui';
import {ScaleLoader} from 'react-spinners';
import {downloadExcel} from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
interface ShippingProps {
  tableData: Array<any>;
  tableHeading: Array<any>;
  getAgents: any;
  onChange: any;
  next: any;
  page: any;
  current: number;
  previous: any;
  pageClick: any;
  Total: any;
  To: any;
  From: any;
  loading: boolean;
  Active: string;
}

const ShippingAgent = (props: ShippingProps) => {
  const [AllData, setAllData] = useState([]);
  let navigate = useNavigate();
  const token = useSelector((state: any) => state.auth.token);
  const dispatch = useDispatch();
  let local: any = localStorage.getItem('userInfo');
  const permissionsArray = JSON.parse(local).permissions;

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  };

  useEffect(() => {
    $('#shipTable').DataTable().destroy();
    $('#shipTable').find('tbody').empty();
    
    getAllAgents();

    return () => {
      $('#shipTable').DataTable().destroy();
      $('#shipTable').find('tbody').empty();
    };
  }, []);

  const getAllAgents = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'agents/getAll'
    });
    if (result) {
      setLoading(false);
      let response = result.data;
      setAllData(response);
      const tableData = result.data.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item?.id,
          firstName: item.firstName,
          lastName: item.lastName,
          name: item.firstName + ' ' + item.lastName,
          email: item.email,
          phone: item.phone,
          address: item.address,
          status: item.status == '1' ? 'Active' : 'In-Active'
        };
      });

      setTimeout(() => {
        $('#shipTable').DataTable({
          data: tableData,
          columns: [
            {data: 'index'},
            {data: 'name'},
            {data: 'email'},
            {data: 'phone'},
            {data: 'address'},
            {data: 'status'},
            {
              title: 'Action',
              render: function () {
                return `<div class='iconWapper'>
                   <img src="/assets/purchaseIcon.svg"
                    data-toggle="tooltip"
                    data-html="true"
                    id="amount"
                    data-bs-placement="top"
                    title="Amount" height="600"
                    >
                    
                    <img src="/assets/edit.svg"
                    data-toggle="tooltip"
                    id="edit"
                    data-html="true"
                    data-bs-placement="top"
                    title="Edit" height="600"
                    >
                    
                    <img src="/assets/delete.svg"
                    data-toggle="tooltip"
                    data-html="true"
                    data-bs-placement="top"
                    id="delete"
                    title="Delete" height="600"
                    >

                   </div>`;
              },

              searchable: false,
              orderable: false
            }
          ]
        });

        const table: any = document.querySelector('#shipTable');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#shipTable').DataTable();
          $('#shipTable tbody').on('click', '#amount', function () {
            var data = table.row($(this).parents('tr')).data();
            let amountObj = {
              id: data.id,
              agentName: data.name
            };
            navigate(`/admin/shipping-agents/amounts`, {
              state: amountObj
            });
          });

          $('#shipTable tbody').on('click', '#edit', function () {
            var data = table.row($(this).parents('tr')).data();
            navigate(`/admin/shipping-agents/update`, {state: data});
          });
          $('#shipTable tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            console.log('delete data: ' + data);
            deleteShipmentById(data?.id);
          });
        });
      }, 1000);
    }
  };

  const deleteShipmentById = (id: any) => {
    swal({
      title: 'Are you sure ?',
      text: 'You want to delete agent ?',
      icon: 'warning',
      buttons: [true, true],
      closeOnClickOutside: false
    }).then(async (AddProduct) => {
      if (AddProduct) {
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'delete',
          url: `agents/delete/${id}`
        });
        if (result) {
          navigate(0);
        }
        // await axios({
        //   url: `${BASE_URL}agents/delete/${id}`,
        //   headers: config,
        //   method: 'delete',
        //   onDownloadProgress(progressEvent: {loaded: number; total: number}) {
        //     let progressB = (progressEvent.loaded / progressEvent.total) * 100;
        //     dispatch(progressLoader(progressB));
        //   },
        //   onUploadProgress: (progressEvent) => {
        //     let progressB = (progressEvent.loaded / progressEvent.total) * 100;
        //     dispatch(progressLoader(progressB));
        //     // setProgress(progressB)
        //   }
        // }).then((res: any) => {
        //   if (res.data.meta.statusCode == 200) {
        //     navigate(0)
        //     getAllAgents()
        //   }
        // });
      }
    });
  };

  const PdfGenerate = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(20);

    const rows = AllData?.map((item: any, index: any) => {
      return [
        index + 1,
        item.firstName + ' ' + item.lastName,
        item.email,
        item.phone,
        item.address,
        item.status == '1' ? 'Active' : 'In-Active'
      ];
    });

    const headers = [['S.No.', 'Name', 'Email', 'Phone', 'Address', 'Status']];
    let content = {
      startY: 60,
      head: headers,
      body: rows
    };
    // @ts-ignore
    doc.autoTable(content);
    doc.save(
      `${new Date().toLocaleDateString('en-GB')} Shipment Agent Table.pdf`
    );
  };

  const ExcelGenerate: any = () => {
    const Heading: any = [
      'S.No.',
      'Name',
      'Email',
      'Phone',
      'Address',
      'Status'
    ];
    const rows: any = AllData?.map((item: any, index: any) => {
      return [
        index + 1,
        item.firstName + ' ' + item.lastName,
        item.email,
        item.phone,
        item.address,
        item.status == '1' ? 'Active' : 'In-Active'
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString(
        'en-GB'
      )} Shipment Agent Table`,
      sheet: `${new Date().toLocaleDateString('en-GB')} Shipment Agent Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };

  return (
    <div className="container-fluid">
      <div>
        <div className="d-flex justify-content-end">
          <div className="btn-group">
            <div className="dropdown">
              <button
                className="btn btn-dark mr-3 btn-lg mb-3  dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Export
              </button>

              <div
                className="dropdown-menu ExportDropdown col-2 dropdownImg"
                aria-labelledby="dropdownMenuButton"
              >
                <div
                  className="dropdown-item d-flex flex-row"
                  onClick={() => {
                    ExcelGenerate();
                  }}
                  style={{cursor: 'pointer'}}
                >
                  <img
                    src="/assets/excel.png"
                    className="mx-2"
                    style={{
                      height: '30px',
                      width: '30px',
                      margin: 0
                    }}
                  />
                  Excel
                </div>
                <div className="dropdown-divider"></div>
                <div
                  onClick={() => {
                    PdfGenerate();
                  }}
                  className="dropdown-item d-flex flex-row "
                  style={{cursor: 'pointer'}}
                >
                  <img
                    src="/assets/pdf.png"
                    className="mx-2"
                    style={{
                      height: '30px',
                      width: '30px',
                      margin: 0
                    }}
                  />
                  PDF
                </div>
              </div>
            </div>
          </div>

          <div className="md-col-2">
            <AppButton
              children={'Add New'}
              className="btn-lg"
              onClick={() => {
                navigate('/admin/shipping-agents/add');
              }}
            />
          </div>
        </div>
      </div>

      {props?.loading ? (
        <div>
          <div className="d-flex justify-content-center">
            <ScaleLoader color="#007bff" height={40} />
          </div>
        </div>
      ) : (
        <>
          <table id="shipTable" className="table table-hover">
            <thead className="thead bg-primary">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
          </table>
        </>
      )}
    </div>
  );
};

export default ShippingAgent;
