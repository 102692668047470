import React from 'react';

const PoshInputs = (props: any) => {
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-12 form-group">
          <label htmlFor="validationCustom03" className="form-label mb-0 ml-1 d-flex">
            <span>{props.title}</span>
            <span style={{color: 'red'}}>{props.validate}</span>
          </label>
          <input
            onChange={props.onChange}
            value={props.value}
            required
            type={props.type}
            placeholder={props.title}
            className={`form-control  mt-2 " ${
              props.hideControl && 'hideNumber_controls'
            }`}
            id="validationCustom03"
          />
        </div>
      </div>
    </div>
  );
};

export default PoshInputs;
