import {useNavigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import './Tableinput.css';
import AppButton from '../button/Button';
import swal from 'sweetalert';
import {useDispatch} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import {ScaleLoader} from 'react-spinners';
import ContentHeader from '../content-header/ContentHeader';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import moment from 'moment';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

const UsersRoles = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    $('#userRoleTable').DataTable().destroy();
    $('#userRoleTable').find('tbody').empty();

    getUserRoles();
    
    return () => {
      $('#userRoleTable').DataTable().destroy();
      $('#userRoleTable').find('tbody').empty();
    };
  }, []);

  const getUserRoles = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'roles/getAll'
    });
    if (result) {
      let response = result.data?.data || result.data;
      const tableData = response?.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item?.id,
          name: item?.roleName,
          date: moment(item?.created_at).format('MM/DD/YYYY')
        };
      });

      setTimeout(() => {
        tableData &&
          $('#userRoleTable').DataTable({
            data: tableData,
            columns: [
              {data: 'index'},
              {data: 'name'},
              {data: 'date'},
              {
                title: 'Action',
                render: function () {
                  return `
                <div class='iconWapper'>

                <img src="/assets/eye.svg"
                data-toggle="tooltip"
                id="view"
                data-html="true"
                data-bs-placement="top"
                title="View" height="600"
                >

                <img src="/assets/edit.svg"
                data-toggle="tooltip"
                id="edit"
                data-html="true"
                data-bs-placement="top"
                title="Edit" height="600"
                >
                
                <img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >

                </div>`;
                },

                searchable: false,
                orderable: false
              }
            ]
          });

        const table: any = document.querySelector('#userRoleTable');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#userRoleTable').DataTable();
          $('#userRoleTable tbody').on('click', '#view', function () {
            var data = table.row($(this).parents('tr')).data();
            navigate(`/admin/user-and-role/userroles/view`, {state: data?.id});
          });
          $('#userRoleTable tbody').on('click', '#edit', function () {
            var data = table.row($(this).parents('tr')).data();
            navigate(`/admin/user-and-role/userroles/edit`, {state: data?.id});
          });
          $('#userRoleTable tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            console.log('delete data: ' + data);
            deleteUserRoles(data?.id);
          });
        });
      }, 1000);
      setloading(false);
    }
  };

  const deleteUserRoles = (id: number) => {
    swal({
      title: 'Are you sure ?',
      text: 'You want to delete userrole ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false
    }).then(async (response: any) => {
      if (response) {
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'delete',
          url: `roles/delete/${id}`
        });
        if (result) {
          setLoading(false);
          navigate(0);
        }
      }
    });
  };

  return (
    <>
      <ContentHeader title="User Roles" />
      <section className=" bg-white p-4 rounded m-3">
        <div className="d-flex justify-content-end">
          <div className="md-col-2 ">
            <AppButton
              children={'Add New'}
              className="btn-lg mb-3 "
              onClick={() => {
                navigate('/admin/user-and-role/userroles/add');
              }}
            />
          </div>
        </div>

        {loading ? (
          <div>
            <div className="d-flex justify-content-center">
              <ScaleLoader color="#007bff" height={40} />
            </div>
          </div>
        ) : (
          <>
            <table id="userRoleTable" className="table table-hover">
              <thead className="bg-primary">
                <tr>
                  <th>S.No.</th>
                  <th>Name </th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
            </table>
          </>
        )}
      </section>
    </>
  );
};

export default UsersRoles;
