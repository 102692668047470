import React, {useRef, useState} from 'react';
import './model.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

export default function IncentiveModal(props?: any) {
  let quantityTotal: any;
  let productTotal: any;
  let currenttotalAmount: any;
  let CalculatedIncentive: any;

  const printContentRef = useRef<HTMLDivElement>(null);
  let totalCases = 0;
  let totalAmount = 0;
  let totalIcentive = 0;
  let totalCurrentAmount = 0;
  let companyName = '';

  return (
    <>
      {props?.data ? (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="bg-primary">
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-3">
            <table
              className="table table-hover "
              style={{border: '1px solid #dee2e6'}}
            >
              <thead className="thead bg-light">
                <tr>
                  <th scope="col">Product category</th>
                  <th scope="col">Flavor</th>
                  <th scope="col">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {props.data?.map((items: any, index: number) => {
                  let sumWithInitial = props.data?.reduce(
                    (previousValue: any, currentValue: any) =>
                      +previousValue + +currentValue.quantity,
                    0
                  );
                  quantityTotal = sumWithInitial;
                  return (
                    <tr key={index}>
                      <td>
                        {items?.flavorCategory
                          ? items?.flavorCategory
                          : items?.flavourCategory?.label}
                      </td>
                      <td>
                        {items?.flavor ? items?.flavor : items?.flavour?.label}
                      </td>
                      <td>{items.quantity}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td>Total Quantity</td>
                  <td>{quantityTotal!}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Total Products</td>
                  <td>{props.data.length}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-light" onClick={props.onHide}>
              Close
            </Button>
            <Button
              disabled={props.generateBtnDisable}
              onClick={props.addShipments}
            >
              Generate
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="bg-primary">
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="m-3" ref={printContentRef}>
            <div className="col-xxl-12 table-responsive">
              <h2 className="print-heading">Incentive Calculation</h2>

              <table
                className="table table-hover print-table"
                style={{
                  border: '1px solid #dee2e6',
                  whiteSpace: 'nowrap'
                }}
              >
                <thead className="thead bg-light">
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Invoice #</th>
                    <th scope="col">Allocation Date</th>
                    <th scope="col">Total Cases</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Incentive</th>
                    <th scope="col">Current Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {props?.modelarray?.map((item: any, index: number) => {
                    let incentive_total_amount = props?.modelarray?.reduce(
                      (previousValue: any, currentValue: any) =>
                        +previousValue + +currentValue.incentive_amount,
                      0
                    );
                    currenttotalAmount = incentive_total_amount;
                    let calculated_incentive_total_amount =
                      props?.modelarray?.reduce(
                        (previousValue: any, currentValue: any) =>
                          +previousValue +
                          (currentValue.grand_total -
                            +currentValue.incentive_amount),
                        0
                      );
                    let currentAmount =
                      item.grand_total - +item.incentive_amount;

                    CalculatedIncentive = calculated_incentive_total_amount;

                    companyName = index === 0 ? item.companyName : companyName;
                    totalCases = +totalCases + +item.total_cases;
                    totalAmount = +totalAmount + +item.grand_total;
                    totalIcentive = +totalIcentive + +item.incentive_amount;
                    totalCurrentAmount = +totalCurrentAmount + +currentAmount;

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.companyName}</td>
                        <td>
                          {item.custFirstName} {item.custLastName}
                        </td>
                        <td>{item.id}</td>
                        <td>{moment(item.created_at).format('MM/DD/YYYY')}</td>
                        <td>{item.total_cases}</td>
                        <td>{item.grand_total}</td>
                        <td>{item.incentive_amount}</td>
                        <td>{currentAmount}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>{totalCases}</td>
                    <td>{totalAmount}</td>
                    <td>{totalIcentive}</td>
                    <td>{totalCurrentAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="d-flex align-items-start flex-column">
              <div className="p-2">
                Customer's current incentive amount: {props.customerIncentive}
              </div>
              <div className="p-2">
                Calculated incentive amount: {CalculatedIncentive}
              </div>
              <div className="p-2">
                Customer's total incentive amount after calculation:{' '}
                {props.customerIncentive + CalculatedIncentive}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-light" onClick={props.onHide}>
              Close
            </Button>
            <ReactToPrint
              trigger={() => {
                return <Button>Print</Button>;
              }}
              content={() => {
                return printContentRef.current;
              }}
              documentTitle={`${companyName} Incentive Calculation`}
            />
            <Button onClick={props.giveIncentive}>Calculate & Update</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
